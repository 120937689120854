import { CalendarModule, DateAdapter } from 'angular-calendar';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';

import { AccessibilityGuard } from './auth/accessibility.guard';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { ApplicationsComponent } from './template/theme/components/applications/applications.component';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { BlankComponent } from './template/pages/blank/blank.component';
import { BreadcrumbComponent } from './template/theme/components/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CustomOverlayContainer } from './template/theme/utils/custom-overlay-container';
import { DxPopupModule } from 'devextreme-angular';
import { ErrorComponent } from './template/pages/errors/error/error.component';
import { FlagsMenuComponent } from './template/theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './template/theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './template/theme/components/menu/horizontal-menu/horizontal-menu.component';
import { HttpClientModule } from '@angular/common/http';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MessagesComponent } from './template/theme/components/messages/messages.component';
import { NgModule } from '@angular/core';
import { NgxLoadingModule } from 'ngx-loading';
import { NotFoundComponent } from './template/pages/errors/not-found/not-found.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { PagesComponent } from './template/pages/pages.component';
import { PipesModule } from './template/theme/pipes/pipes.module';
import { SearchComponent } from './template/pages/search/search.component';
import { SharedModule } from './template/shared/shared.module';
import { SidenavComponent } from './template/theme/components/sidenav/sidenav.component';
import { StorageService } from './services/branchstorage.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TranslateModule } from '@ngx-translate/core';
import { UserMenuComponent } from './template/theme/components/user-menu/user-menu.component';
import { VerticalMenuComponent } from './template/theme/components/menu/vertical-menu/vertical-menu.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgScrollbarModule } from 'ngx-scrollbar';
@NgModule({
  imports: [
     MatMomentDateModule,
     BrowserModule,
     BrowserAnimationsModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    AppRoutingModule,
    PipesModule,
    HttpClientModule,
    DxPopupModule,
    SweetAlert2Module.forRoot(),
     TranslateModule.forRoot({
      defaultLanguage: 'en'
  }),
  NgScrollbarModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
  ],
   exports: [TranslateModule],
  providers: [
    AppSettings,
    AccessibilityGuard,
    
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
      { provide: JWT_OPTIONS, useValue: JWT_OPTIONS }, JwtHelperService, AuthService, AuthGuardService, StorageService,
      
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }