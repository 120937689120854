import { Component, OnInit, ViewChild } from '@angular/core';

import { DxNumberBoxComponent } from 'devextreme-angular';
import { SharedService } from '../shared.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit {
  loading: boolean;
  showPanel: boolean;
  orderNumber: any;
  @ViewChild('inputElement') inputElement: DxNumberBoxComponent;
  items: any[];
  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.showPanel = false;
    this.items = [
      //  {
      //   status: true,
      //   name: 'Qc Power'
      //  },
      //  {
      //   status: true,
      //   name: 'Tint In'
      //  },
      //  {
      //   status: true,
      //   name: 'Tint Qc'
      //  },
      //  {
      //   status: false,
      //   name: 'Tint Out'
      //  },
      //  {
      //   status: false,
      //   name: 'HC In'
      //  },
    ]
  }

  togglePanel() {

    this.showPanel = !this.showPanel;
    if (this.showPanel) {
      this.inputElement.instance.focus();
    } else {
        this.orderNumber = null;
    }
    

  }

  /**
   * @name getOrderStatus
   * @param event 
   * @return order status object
   */
  getOrderStatus(event: any, value) {  
    // event && event.event.key == "Enter" &&
    if (value) { 
      // console.log('value', value)
      this.loading = true;
      this.sharedService.getSalesOrderStatus(value, JSON.parse(localStorage.getItem('selectedCustomerBranch')).companyBranchId)
      .then(response => {
        if (response.data.completed) {
          let items = [];
          const objectArray = Object.entries(response.data.payload);
          objectArray.forEach(([key, value]) => {
            let key1 = '';
            let key2 = '';
            if(key.includes('Done')){
              key1 = key.split('is')[1].split('Done')[0];
              key2 = 'Done';
            }
            else{
              key1 = key.split('is')[1].split('Required')[0];
              key2 = 'Required';
            }
            let name = key1 + ' ' + key2; 
            items.push(
              {
                status: value,
                name: name
              }
            )
          });
          items.sort((a, b) => a.name < b.name ? - 1 : Number(a.name > b.name));
          this.items = items;
        }
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        const option = {
          width: 300,
          displayTime: 3000,
          message: err.message,
          type: 'error'
        };
        notify(option);
      });
    }
  }
}
