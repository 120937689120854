import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from "@angular/core";

import Query from "devextreme/data/query";

declare global {
  interface Array<T> {
    flexFilter(o: T): Array<T>;
  }
}
@Component({
  selector: "app-panel",
  templateUrl: "./app-panel.component.html",
  styleUrls: ["./app-panel.component.scss"]
})

export class AppPanelComponent implements OnInit, OnChanges {
  @Input() showPanel: boolean = true;
  toggle: boolean = false;
  @Input() footer: boolean = false;
  @Input() header: boolean = true;
  @Input() backArrow: boolean = true;
  @Input() filters: boolean = false;
  @Input() toggling: boolean = true;
  @Input() filterList: any[];
  
  @Input() panelFilterOptions: any = {
    brandMasterId: true,
    subBrandMasterId: true,
    focalId: true,
    indexId: true,
    filterBrandId: true,
    coatingBrandId: true,
    itemSizeId: true,
    colorMasterId: true,
    customerId: true,
    pendingStatusId: true,
    salesOrderTypeId: true
  };
  @Input() filterColumns: any = {
    brandMasterId: 2,
    subBrandMasterId: 2,
    focalId: 2,
    indexId: 2,
    filterBrandId: 2,
    coatingBrandId: 2,
    itemSizeId: 2,
    colorMasterId: 2,
    customerId: 2,
    pendingStatusId: 2,
    salesOrderTypeId: 2
  };
  isFilterEnable: boolean;
  filterDataSource: any = {};
  @Output() selectedFilters: EventEmitter<any> = new EventEmitter();

  @ViewChild("expand") private expand: ElementRef;
  @ViewChild("compress") private compress: ElementRef;
  temp: any;
  constructor() {
    
  }

  ngOnInit() {
    this.temp = [];
    // this.showPanel = true;
    this.extentArray();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (this.filters && changes.filterList) {
      if (Object.keys(changes.filterList.currentValue).length > 0 ) {
        this.temp = changes.filterList.currentValue;
      }
    }
  }
  /**
   * @name requestFullscreen
   * @param element
   * @return full screen
   */
  requestFullscreen(elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else {
      console.log("Fullscreen API is not supported.");
    }
  }

  /**
   * @name exitFullscreen
   * @return void
   */
  exitFullscreen() {
    if ((<any>document).exitFullscreen) {
      (<any>document).exitFullscreen();
    } else if ((<any>document).webkitExitFullscreen) {
      (<any>document).webkitExitFullscreen();
    } else if ((<any>document).mozCancelFullScreen) {
      (<any>document).mozCancelFullScreen();
    } else if ((<any>document).msExitFullscreen) {
      (<any>document).msExitFullscreen();
    } else {
      console.log("Fullscreen API is not supported.");
    }
  }

  @HostListener("click") getFullscreen() {
    if (this.expand) {
      this.requestFullscreen((<any>document).documentElement);
    }
    if (this.compress) {
      this.exitFullscreen();
    }
  }

  @HostListener("window:resize") onFullScreenChange() {
    let fullscreenElement =
      (<any>document).fullscreenElement ||
      (<any>document).mozFullScreenElement ||
      (<any>document).webkitFullscreenElement ||
      (<any>document).msFullscreenElement;
    if (fullscreenElement != null) {
      this.toggle = true;
    } else {
      this.toggle = false;
    }
  }

  togglePanel() {
    this.showPanel = !this.showPanel;
  }

  /**
   * @name filterChangeEvent
   * @returns boolean
   */
  filterChangeEvent() {
    this.isFilterEnable = !this.isFilterEnable;
  }

  /**
   * @name submitFilter
   * @param filter data
   * @return filter data source
   */
  submitFilter(result) {
    const f = {
      brandMasterId: result.Brand ? result.Brand : 0,
      subBrandMasterId: result.SubBrand ? result.SubBrand : 0,
      focalId: result.Focal ? result.Focal : 0,
      indexId: result.Index ? result.Index : 0,
      filterBrandId: result.FilterBrand ? result.FilterBrand : 0,
      coatingBrandId: result.CoatingBrand ? result.CoatingBrand : 0,
      itemSizeId: result.ItemSize ? result.ItemSize : 0,
      colorMasterId: result.ColorMaster ? result.ColorMaster : 0,
      customerId: result.Customer ? result.Customer : 0,
      salesOrderTypeId: result.OrderType ? result.OrderType : 0,
      pendingStatusId: result.PendingStatus ? result.PendingStatus : 0,
    };
    
    let t = [];
    for (let [key, value] of Object.entries(f)) { 
      if (value > 0) {
        t.push( {
          Field: key,
          Values : [value]
        });
      }
    }
    // const b = Query(this.temp)
    //         .filter([ "customerId", "=", f.customerId ])
    //         .toArray();
    const temp = this.temp.flexFilter(t);
      if (temp.length > 0) {
       this.selectedFilters.emit(temp);
       // return (this.filterList = temp);
      } else {
        this.selectedFilters.emit([]);
        // this.filterList = [];
      }
  }

  /**
   * @name extentArray
   * @returns add new flexFilter function
   */
  extentArray() {
    Array.prototype.flexFilter = function(info) {
      return this.filter(item => {
        return info.every(i => {
          return i.Values.indexOf(item[i.Field]) > -1;
        });
      });
    };
  }
}
export interface Array<T> {
  flexFilter(o: T): Array<T>;
}
