import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from "@angular/core";
import { SharedService } from "../shared.service";
import { FormGroup, FormControl } from "@angular/forms";
import * as moment from 'moment';


@Component({
  selector: 'app-sale-inquiry-filter',
  templateUrl: './sale-inquiry-filter.component.html',
  styleUrls: ['./sale-inquiry-filter.component.scss']
})
export class SaleInquiryFilterComponent implements OnInit, OnChanges {
  @Input() configOptions: any;
  @Input() filterDataSource: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  filterForm: FormGroup;
  customersList: any[];
  orderStatusList: any[];
  orderTypeList: any[];
  brandList: any[];
  subBrandList: any[];
  constructor(private sharedService: SharedService) { }

  ngOnInit() {

    // DROPDOWNS
    this.getAllCustomers();
    this.getAllSalesOrderPendingStatuss();
    this.getAllSalesOrderTypes();
    this.getAllBrands();
    // SET CONFIGRATION
    // this.configOptions = {
    //   salesOrderId: true,
    //   customerId: true,
    //   pendingStatusId: true,
    //   salesOrderTypeId: true,
    //   brandMasterId: true,
    //   subBrandMasterId: true,
    //   fromDate: true,
    //   toDate: true
    // };

    // RESET VARIABLES
    this.customersList = [];
    this.orderStatusList = [];
    this.orderTypeList = [];
    this.brandList = [];
    this.subBrandList = [];
    this.getInitForm();
  }
 /**
     // tslint:disable-next-line: no-redundant-jsdoc
     * @description ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
      console.log(changes)
      this.configOptions = changes.configOptions.currentValue;
     // this.getInitForm(changes.filterDataSource.currentValue);
  }

 /**
   * @name getInitForm
   * @description filter form 
   * @param filter datascouce 
   */
  getInitForm(filterData?: any) {
    this.filterForm = new FormGroup({
      salesOrderId: new FormControl(filterData ? filterData.salesOrderId : "", []),
      customerId: new FormControl(filterData ? filterData.customerId : "", []),
      pendingStatusId: new FormControl(filterData ? filterData.pendingStatusId : "", []),
      salesOrderTypeId: new FormControl(filterData ? filterData.salesOrderTypeId : "", []),
      brandMasterId: new FormControl(filterData ? filterData.brandMasterId : "", []),
      subBrandMasterId: new FormControl(filterData ? filterData.subBrandMasterId : "", []),
      fromDate: new FormControl(filterData ? filterData.fromDate : "", []),
      toDate: new FormControl(filterData ? filterData.toDate : "", []),
    });
  }

  /**
   * @name getAllCustomers
   * @returns Promise
   * @description fetch all Customer
   */
  getAllCustomers() {
    this.sharedService
      .getAllCustomers()
      .then(response => {
        if (response.data.completed) {
          // this.customersList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.customersList = list;
          } else {
            if (response.data.payload.active) {
              this.customersList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

  /**
   * @name getAllSalesOrderPendingStatuss
   * @returns Promise
   * @description fetch all SalesOrderPendingStatus
   */
  getAllSalesOrderPendingStatuss() {
    this.sharedService
      .getAllSalesOrderPendingStatuss()
      .then(response => {
        if (response.data.completed) {
          // this.orderStatusList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.orderStatusList = list;
          } else {
            if (response.data.payload.active) {
              this.orderStatusList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }

   /**
   * @name getAllSalesOrderTypes
   * @returns Promise
   * @description fetch all SalesOrderType
   */
  getAllSalesOrderTypes() {
    this.sharedService
      .getAllSalesOrderTypes()
      .then(response => {
        if (response.data.completed) {
          this.orderTypeList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.orderTypeList = list;
          } else {
            if (response.data.payload.active) {
              this.orderTypeList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
  
    /**
   * @name getAllMaterials
   * @returns Promise
   * @description fetch all item
   */
  getAllBrands() {
    this.sharedService
      .getAllBrands()
      .then(response => {
        if (response.data.completed) {
          // this.brandList = response.data.payload;
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.brandList = list;
          } else {
            if (response.data.payload.active) {
              this.brandList = response.data.payload;
            }
          }
        }
      })
      .catch(err => {
        console.error("Error:  ", err);
      });
  }
 /**
   * @name getAllSubBrandsByBrandMasterId
   * @returns Promise
   * @description fetch all SubBrand
   */
  getAllSubBrandsByBrandMasterId(brandMasterId) {
    if (brandMasterId) {
      this.subBrandList = [];
      this.sharedService
        .getAllSubBrandsByBrandMasterId(brandMasterId)
        .then(response => {
          if (response.data.completed) {
            // this.subBrandList = response.data.payload;
            if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.subBrandList = list;
          } else {
            if (response.data.payload.active) {
              this.subBrandList = response.data.payload;
            }
          }
          }
        })
        .catch(err => {
          console.error("Error:  ", err);
        });
    }
  }

  /**
   * @name submitFilter
   * @returns filter controls value
   */
  submitFilter(form: FormGroup) {
  //   Object.keys(form.value).filter(a => form.value[a] !== null).forEach(key => {
  //     map[key] = form.value[key];
  // });
  const payload = {
    salesOrderId: form.value.salesOrderId,
    customerId: form.value.customerId,
    pendingStatusId: form.value.pendingStatusId,
    salesOrderTypeId: form.value.salesOrderTypeId,
    brandMasterId: form.value.brandMasterId,
    subBrandMasterId: form.value.subBrandMasterId,
    fromDate: moment(form.value.fromDate).format('MM/DD/YYYY'),
    toDate: moment(form.value.toDate).format('MM/DD/YYYY')
  };
    this.optSelected.emit(payload);
  }
}
