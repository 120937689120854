import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';

import { RingToneService } from './../../../services/ringtone.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-capture-box',
  templateUrl: './capture-box.component.html',
  styleUrls: ['./capture-box.component.scss']
})
export class CaptureBoxComponent implements OnInit {
  @Input() heading: string;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  @Output() captureImageSource: EventEmitter<any> = new EventEmitter();
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  constructor (
    private toneService: RingToneService
  ) {}

  public ngOnInit(): void {

  }
  public ngAfterViewInit(): void {
    // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    // Add 'implements AfterViewInit' to the class.
    WebcamUtil.getAvailableVideoInputs()
    .then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
    });
  }

  public triggerSnapshot(): void {
    this.toneService.audio('play', '/assets/media/take_picture.mp3');
    this.trigger.next(null);
    setTimeout(
      () => {
        this.toneService.audio('pause');
      },
      1100
    );
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    const option = {
      width: 300,
      displayTime: 3000,
      message: error.message,
      type: 'error'
    };
    notify(option);
    console.error(error);
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.info('received webcam image', webcamImage);
    this.showWebcam = false;
    this.webcamImage = webcamImage;
    this.captureImageSource.emit(webcamImage);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
    this.captureImageSource.emit(null);
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  proceed(): void {
    console.log(this.webcamImage);
    // this.visitorService.setCapturedImage = this.webcamImage;
  }

}
