import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    private _selectedBranch = null;
    private _selectedUser = null;
    constructor() { }
    set setBranch(value) {
        this._selectedBranch = value;
    }
    get getBranch() {
        return this._selectedBranch = JSON.parse(localStorage.getItem('selectedCustomerBranch'));
    }
    get getUserInfo() {
        return this._selectedUser = JSON.parse(localStorage.getItem('userData'));
    }
    resetBranch () {
        this._selectedBranch = null;
    }
}