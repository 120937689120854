<div class="stock-form-container">
<div class="container">
  <form [formGroup]="stockRequestForm" (ngSubmit)="saveStockRequest(stockRequestForm)">
      <div class="row justify-content-center mt-2">
          <fieldset class="col-3 px-3 animated fadeInDown">
              <!-- <legend>
                  {{componentTitle}}
                  <small class="d-block text-muted text-truncate">Dehlawi Optical Industries Co. LTD</small>
              </legend> -->
              <div class="justify-content-center">
                  <div class="row">
                      <label class="col-5 col-form-label mt-2">Request Date</label>
                      <div class="col-7 pl-0">
                          <mat-form-field class="width-inherit px-2">
                              <input matInput [matDatepicker]="picker" formControlName="requestedDate" autocomplete="off">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker touchUi #picker></mat-datepicker>
                          </mat-form-field>
                      </div>
                  </div>
              </div>
          </fieldset>
      </div>
      <div class="row justify-content-center mt-3">
          <fieldset class="col-7 mx-1 animated fadeInLeft">
              <legend>
                  Demand
                  <small class="text-muted">Request By</small>
              </legend>
              <div class="justify-content-center">
                  <div class="form-row">
                      <div class="form-group col">
                          <label class="pl-2">Branch Name</label>
                          <mat-form-field class="width-inherit px-2">
                              <mat-select formControlName="demandRequestedByBranchName" disabled>
                                  <mat-option *ngFor="let companyBranch of allCompanyBranch" [value]="companyBranch.companyBranchId">
                                      {{companyBranch.companyBranchName}}
                                  </mat-option>
                              </mat-select>
                              <mat-error *ngIf="stockRequestForm.get('demandRequestedByBranchName').errors">Demand request reason is required</mat-error>
                          </mat-form-field>
                      </div>
                      <div class="form-group col">
                          <label class="pl-2">Store Name</label>
                          <mat-form-field class="width-inherit px-2">
                              <mat-select formControlName="demandRequestedByStoreName">
                                  <mat-option *ngFor="let branchStore of companyBranchStore1" [value]="branchStore.storeId">
                                      {{branchStore.storeName}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="form-group col">
                        <label class="pl-2">Adjustment Reason</label>
                        <mat-form-field class="width-inherit px-2">
                            <mat-select formControlName="adjustmentReason">
                                <mat-option *ngFor="let ad of adjustmentList" [value]="ad.adjustmentReasonId">
                                    {{ad.reasonName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="stockRequestForm.get('adjustmentReason').errors">Adjustment reason is required</mat-error>
                        </mat-form-field>
                    </div>
                      <!-- <div class="form-group col">
                          <label class="pl-2">Requested By</label>
                          <mat-form-field class="width-inherit px-2">
                              <input matInput formControlName="demandRequestedByRequestedUser">
                          </mat-form-field>
                      </div> -->
                  </div>
              </div>
          </fieldset>
          <!-- <fieldset class="col mx-1 animated fadeInRight">
              <legend>
                  Demand
                  <small class="text-muted">Request To</small>
              </legend>
              <div class="justify-content-center">
                  <div class="form-row">
                      <div class="form-group col">
                          <label class="pl-2">Branch Name</label>
                          <mat-form-field class="width-inherit px-2">
                              <mat-select formControlName="demandRequestedToBranchName" (selectionChange)="getSecondCompanyBranchStore($event.value)">
                                  <ng-container *ngFor="let companyBranch of allCompanyBranch">
                                      <mat-option [value]="companyBranch.companyBranchId"
                                                  *ngIf="companyBranch.companyBranchId !== stockRequestForm.value.demandRequestedByBranchName">
                                          {{companyBranch.companyBranchName}}
                                      </mat-option>
                                  </ng-container>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div class="form-group col">
                          <label class="pl-2">Store Name</label>
                          <mat-form-field class="width-inherit px-2">
                              <mat-select formControlName="demandRequestedToStoreName">
                                  <mat-option *ngFor="let branchStore of companyBranchStore2" [value]="branchStore.storeId">
                                      {{branchStore.storeName}}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
              </div>
          </fieldset> -->
      </div>
      <div class="row mt-3">
          <div class="col animated fadeInUp">
              <div class="form-row">
                  <div class="form-group col">
                      <label class="pl-2">Item Type</label>
                      <mat-form-field class="width-inherit px-2">
                          <mat-select formControlName="itemType">
                              <mat-option *ngFor="let itemType of allItemTypes" [value]="itemType.itemTypeId">
                                  {{itemType.itemTypeName}}
                              </mat-option>
                          </mat-select>
                          <mat-error *ngIf="stockRequestForm.get('itemType').errors">Item type is required</mat-error>
                      </mat-form-field>
                  </div>
                  <div class="form-group col">
                      <label class="pl-2">Item Group</label>
                      <mat-form-field class="width-inherit px-2">
                          <mat-select formControlName="itemGroup">
                              <mat-option *ngFor="let itemGroup of allItemGroups" [value]="itemGroup.itemGroupId">
                                  {{itemGroup.itemGroupName}}
                              </mat-option>
                          </mat-select>
                          <mat-error *ngIf="stockRequestForm.get('itemGroup').errors">Item group is required</mat-error>
                      </mat-form-field>
                  </div>
                  <div class="form-group col">
                      <label>Range</label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                      <span class="input-group-text">
                          <small>SPH</small>
                      </span>
                          </div>
                          <input type="number" (input)="checkRange()" placeholder="min" autocomplete="off"
                                 class="form-control hvr-shadow" step="0.25" formControlName="rangeSphMin"
                                 matTooltip="min -15" matTooltipPosition="above">
                          <input type="number" (input)="checkRange()" placeholder="max" autocomplete="off"
                                 class="form-control hvr-shadow" step="0.25" formControlName="rangeSphMax"
                                 matTooltip="max 15" matTooltipPosition="above">
                      </div>
                      <small *ngIf="stockRequestForm.get('rangeSphMin').errors" class="form-text text-right text-danger animated fadeInLeft">
                            Value must be min then -15.
                        </small>
                        <small *ngIf="stockRequestForm.get('rangeSphMax').errors" class="form-text text-right text-danger animated fadeInLeft">
                                Value must be max 15.
                            </small>
                      <small *ngIf="isSphInvalid" class="form-text text-right text-danger animated fadeInLeft">
                          Value must be greater or equal.
                      </small>
                      
                  </div>
                  <div class="form-group col">
                      <label class="invisible">Range</label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                      <span class="input-group-text">
                          <small>CYL</small>
                      </span>
                          </div>
                          <input type="number" (input)="checkRange()" placeholder="min" autocomplete="off"
                                 class="form-control hvr-shadow" step="0.25" formControlName="rangeCylMin"
                                 matTooltip="min -6" matTooltipPosition="above">
                          <input type="number" (input)="checkRange()" placeholder="max" autocomplete="off"
                                 class="form-control hvr-shadow" step="0.25" formControlName="rangeCylMax"
                                 matTooltip="max 0" matTooltipPosition="above">
                      </div>
                      <small *ngIf="stockRequestForm.get('rangeCylMin').errors" class="form-text text-right text-danger animated fadeInLeft">
                            Value must be min -6.
                        </small>
                        <small *ngIf="stockRequestForm.get('rangeCylMax').errors" class="form-text text-right text-danger animated fadeInLeft">
                                Value must be max 0.
                            </small>
                      <small *ngIf="isCylInvalid" class="form-text text-right text-danger animated fadeInLeft">
                          Value must be greater or equal.
                      </small>
                  </div>
              </div>
          </div>
      </div>
      <div class="row mt-2" *ngIf="SPH_RANGE?.length && CYL_RANGE?.length">
          <div class="col">
              <div class="card animated fadeIn">
                  <div class="card-header" hidden>
                      <span class="float-right"> <strong>Date</strong> 7/3/2019</span>
                  </div>
                  <div class="card-body pb-0">
                      <div class="table-responsive-sm">
                          <table class="table table-striped use-equal-cell-width text-center table-with-over">
                              <tbody>
                              <tr>
                                  <th class="force-inline"> CYL / SPH </th>
                                  <!-- adding cyl values here -->
                                  <th *ngFor="let cyl of CYL_RANGE">{{ cyl }}</th>
                              </tr>
                              <tr *ngFor="let sph of SPH_RANGE; let sphIndex = index">
                                  <!-- iterating via row and adding sph values on every first child -->
                                  <td class="align-middle">
                                      <b>{{ sph }}</b>
                                  </td>
                                  <!-- using again to balance column cell -->
                                  <td *ngFor="let cyl of CYL_RANGE; let cylIndex = index">
                                      <!-- {{ cylIndex }} -->
                                      <input type="number" id="input-{{sphIndex + '-' + cylIndex}}" autocomplete="off"
                                             (input)="getRangeValues($event.target.value, CYL_RANGE[cylIndex], SPH_RANGE[sphIndex])"
                                             class="form-control form-control-sm hvr-glow animated fadeInRight">
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row mt-2">
          <div class="col animated fadeInUp">
                <!-- <button type="submit" class="btn btn-default hvr-glow">Cancel</button>
              <button type="submit" class="btn btn-primary hvr-glow" 
              [disabled]="!rangeStockListPayload?.length"
                      >Save</button> -->
                      <div 
                      class="col  animated fadeInUp"><button 
                       class="btn float-right btn-primary hvr-glow" type="submit" [disabled]="!rangeStockListPayload?.length">Save</button><button 
                        class="btn float-right mr-3 btn-secondary hvr-glow" type="button" appBackButton>Cancel</button></div>
                      <!-- <button type="submit" class="btn btn-primary btn-block hvr-glow"
                      [disabled]="(!stockRequestForm.valid || !rangeStockListPayload?.length)">Save</button> -->
          </div>
      </div>
  </form>
</div>
</div>
