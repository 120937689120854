import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";

import { DialogAddRows } from "./../dilog/add-rows.component";
import { MatDialog } from "@angular/material/dialog";
import { SharedService } from "./../../shared.service";
import { Subscription } from "rxjs";
import { UserService } from "./../../core/user.service";
import notify from "devextreme/ui/notify";

declare var $: any;
@Component({
  selector: "inventory-cx",
  templateUrl: "./inventory-cx.component.html",
  styleUrls: ["./inventory-cx.component.scss"],
})
export class InventoryCXGridComponent implements OnInit, OnChanges, OnDestroy {
  requestForm: FormGroup;
  @Input() dataSource: any;
  @Input() itemCatalogGroupId: any;
  @Input() itemCatalogId: any;
  @Input() data: any;
  @Output() optSelected: EventEmitter<any> = new EventEmitter();
  @Input() focalId: any;
  @Input() itemTypeMasterId: any;
  @Input() companyBranchId: any;
  @Input() acknowledgement: any;
  subscription: Subscription;
  loadingIndicator: boolean;
  showGenerateBtn: boolean;
  showPreviousStock: boolean;
  previousStock: boolean;
    currentStock: boolean;
  cxList: any;
  items: any;
  isEdit: boolean;
  cxHeader: any;
  tempcxTypeDetail: any;
  total: number;
  readonly: boolean;
  displayOnly: boolean;
  inputType: boolean;
  cxRangeList: any;
  rangeLoading: boolean;
  filterCxRangeList: any;
  rangeData: any[];
  loadingAllAvailableStock: boolean;
  showAddRowBtn: any;
  showWt: boolean;
  userRemarks: any;
  popupTarget: any;
  actionSheetVisible: boolean;
  loadingBarCode: boolean;
  isBarCodeEnable: boolean;
  totalToggle: boolean;
  // tslint:disable-next-line: max-line-length
  constructor(
    private sharedService: SharedService,
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.total = 0;
    this.showGenerateBtn = false;
    this.cxRangeList = [];
    this.filterCxRangeList = [];
    // this.rangeData = [];
   

    this.getAllCxTypes();
  }

  getInitForm() {
    this.requestForm = new FormGroup({
      sections: new FormArray([]),
    });
  }
  /**
   * @name ngOnChanges
   * @desc Fires when change in input properties are detected
   * @return void
   */
  ngOnChanges(changes: SimpleChanges) {
    this.dataSource = [];
    this.getInitForm();
    if (Object.keys(changes.dataSource && changes.dataSource.currentValue).length > 0) {
      this.rangeData = [];
      this.addSection(changes.dataSource.currentValue);
      this.previousStock = false;
      this.tempcxTypeDetail = changes.dataSource.currentValue.cxTypeDetail;
      this.readonly = changes.dataSource.currentValue.readonly;
      this.displayOnly = changes.dataSource.currentValue.displayOnly;
      this.showPreviousStock = changes.dataSource.currentValue.showPreviousStock;
      this.totalToggle = changes.dataSource.currentValue.totalToggle;
      if (changes.dataSource.currentValue.showWt) {
        this.showWt = true;
            this.cxHeader = [
              {
                name: 'CX Type'
              },
              {
                name: 'Bar Code'
              },
              {
                name : 'QTY'
              },
              {
                name: 'CX'
              },
              {
                name: 'TC'
              },
              {
                name: 'CC'
              },
              {
                name: 'CT'
              },
              {
                name: 'ET'
              },
              {
                name: 'ADD'
              },
              {
                name: 'WT'
                // visible itemTypeMasterId == 4
              },
              {
                name: 'Remarks'
              }
            ];
      } else {
        this.showWt = false;
        this.cxHeader = [
          {
            name: 'CX Type'
          },
          {
            name: 'Bar Code'
          },
          {
            name : 'QTY'
          },
          {
            name: 'CX'
          },
          {
            name: 'TC'
          },
          {
            name: 'CC'
          },
          {
            name: 'CT'
          },
          {
            name: 'ET'
          },
          {
            name: 'ADD'
          },
          {
            name: 'Remarks'
          }
        ];
      }
      this.inputType = changes.dataSource.currentValue.inputType;
      if (changes.dataSource.currentValue.rangeOption) {
        const payload = {
          itemCatalogGroupId: this.itemCatalogGroupId,
          itemTypeMasterId: this.itemTypeMasterId,
          focalId: this.focalId,
          sphmin: 0,
          sphmax: 0,
          cylmin: 0,
          cylmax: 0,
          addmin: 0,
          addmax: 0,
          cxmin:
            changes.dataSource.currentValue.barCodeData.cxmin ||
            changes.dataSource.currentValue.barCodeData.cxmin == 0
              ? changes.dataSource.currentValue.barCodeData.cxmin
              : 0,
          cxmax:
            changes.dataSource.currentValue.barCodeData.cxmax ||
            changes.dataSource.currentValue.barCodeData.cxmax == 0
              ? changes.dataSource.currentValue.barCodeData.cxmax
              : 0,
        };
        // this.barCodeSph = this.sharedService.generateRange((changes.dataSource.currentValue.sphmin as string).replace('-',''),(changes.dataSource.currentValue.sphmax as string).replace('-',''), 0.25);
        // this.barCodeCyl = this.sharedService.generateRange((changes.dataSource.currentValue.cylmin as string).replace('-',''),(changes.dataSource.currentValue.cylmax as string).replace('-',''), 0.25);
        this.validateBarcodeRequestRange(payload);
      }
      if (changes.dataSource.currentValue.cxTypeDetail && changes.dataSource.currentValue.cxTypeDetail.length) {
        for (
          let i = 0;
          i < changes.dataSource.currentValue.cxTypeDetail.length;
          i++
        ) {
          this.createCxTypeDetail(
            changes.dataSource.currentValue.cxTypeDetail[i]
          );
        }
      } else {
        const option = {
          width: 300,
          displayTime: 3000,
          message: 'Cx Details not found...',
          type: 'error'
          };
          notify(option);
      }
      
        //this.generateGrid(changes.dataSource.currentValue.childern[0].min, changes.dataSource.currentValue.childern[0].max);
      this.getCX(
        changes.dataSource.currentValue.childern[0].min,
        changes.dataSource.currentValue.childern[0].max
      );
      if (changes.dataSource.currentValue.isEdit) {
        this.showAddRowBtn = changes.dataSource.currentValue.showAddRowBtn;
        // this.generateGrid(changes.dataSource.currentValue.childern[0].min, changes.dataSource.currentValue.childern[0].max);
        // for (
        //   let i = 0;
        //   i < changes.dataSource.currentValue.cxTypeDetail.length;
        //   i++
        // ) {
        //   this.createCxTypeDetail(
        //     changes.dataSource.currentValue.cxTypeDetail[i]
        //   );
        //   // this.updatedMaxVal('increment');
        // }
        // this.createCxTypeDetail()
        this.isEdit = true;
      }
      this.fixVerticalTabindex(".reset-tabindex");
    }
  }

  /**
   * @name addSection
   * @param data
   * @returns void
   * @description create section array
   */
  addSection(data?: any): void {
    if (data) {
      // data.forEach((controls: any) => {
      if (data.active) {
        console.log(data);
        this.showGenerateBtn = data.generateBtn;
        this.sectionsArray.push(this.createSection(data));
        // const sectionsList = this.requestForm.get('sections') as FormArray;
        // sectionsList.push(this.createSection(data));
      }
      // });
    }
  }

  /**
   * @name createSection
   * @param data
   * @return FormGroup
   * @description section controls
   */
  createSection(data?: any): FormGroup {
    return new FormGroup({
      condition: new FormControl(data ? data.condition : "", []),
      active: new FormControl(data ? data.active : false, []),
      childern: new FormArray(this.addChildern(data.childern)),
      cxTypeDetail: new FormArray([]),
    });
  }

  /**
   * @name addChildern
   * @param data
   * @returns childern array
   */
  addChildern(data?: any) {
    const formArray = [];
    data.forEach((child) => {
      formArray.push(this.createChild(child));
    });
    return formArray;
  }

  /**
   * @name createChild
   * @param child
   * @return  childer controls
   * @deprecated child formcontrols
   */
  createChild(child?): FormGroup {
    return new FormGroup({
      name: new FormControl(child ? child.name : "", []),
      min: new FormControl(child ? child.min : "", []),
      max: new FormControl(child ? child.max : "", []),
    });
  }

  /**
   * @name sectionsArray
   * @returns section array controls
   */
  get sectionsArray() {
    return this.requestForm.get("sections") as FormArray;
  }

  /**
   * @name getChildern
   * @param form section item index
   * @returns childern array controls
   */
  getChildern(form) {
    return form.controls.childern.controls;
  }

  /**
   * @name getCxTypeDetail
   * @param form section item index
   * @returns cxTyeDetail array controls
   */
  getCxTypeDetail(form) {
    return form.controls.cxTypeDetail.controls;
  }

  /**
   * @name createCxTypeDetail
   * @require add cxtypedetail array
   */
  createCxTypeDetail(data?: any) {
    const item = (this.requestForm.get("sections") as FormArray)
      .at(0)
      .get("cxTypeDetail") as FormArray;
    item.push(this.addCxTypeDetail(data));
  }

  /**
   * @name addCxTypeDetail
   * @param data
   * @return FormGroup
   * @description cxtypedetail controls
   */
  addCxTypeDetail(data?: any): FormGroup {
    return new FormGroup({
      cx: new FormControl(data ? data.cx : '', []),
      cxtypeId: new FormControl(data ? data.cxtypeId : 3, []),
      vendorItemCode: new FormControl(data ? data.vendorItemCode : "", []),
      cc: new FormControl(data ? data.cc : "", []),
      add: new FormControl(data ? data.addf : "", []),
      // axis: new FormControl(data ? data.axis : '', []),
      // cyl: new FormControl(data ? data.cyl : '', []),
      ct: new FormControl(data ? data.ct : "", []),
      et: new FormControl(data ? data.et : "", []),
      tc: new FormControl(data ? data.tc : "", []),
      wt: new FormControl(data ? data.wt : "", []),
      qty: new FormControl(data ? data.qty : "", []),
      remarks: new FormControl(data ? data.remarks : "", []),
      vendorItemCatalogId: new FormControl(data ? data.vendorItemCatalogId : "", []),
      isBarCodeEnable: new FormControl(data ? data.isBarCodeEnable : false)
    });
  }

  /**
   * @name validateBarcodeRequestRange
   * @param {number} itemCatalogGroupId
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
  validateBarcodeRequestRange(payload) {
    this.rangeLoading = true;
    this.sharedService
      .validateBarcodeRequestRange(payload)
      .then((response: any) => {
        if (response.data.completed && response.data.payload.length > 0) {
          this.rangeLoading = false;
          this.rangeData = response.data.payload;
        } else {
          this.rangeLoading = false;
          console.error(response);
        }
      })
      .catch((error) => {
        this.rangeLoading = false;
        console.log(error);
      });
  }
  /**
   * @name getCatalogByBarcode
   * @param {string} barCodeId
   * @returns
   * @memberof SharedService
   */
  getCatalogByBarcode(barCodeId, index) {
    const item = (this.requestForm.get("sections") as FormArray).at(0).get("cxTypeDetail") as FormArray;
    if (barCodeId) {
      this.loadingIndicator = true;
    this.sharedService
      .getCatalogByBarcode(barCodeId)
      .then((response: any) => {
        if (response.data.completed && response.data.payload.length > 0 && this.itemCatalogGroupId == response.data.payload[0].itemCatalogGroupId) {
          this.loadingIndicator = false;
          item.controls[index].get("cxtypeId").setValue(response.data.payload[0].cxtypeId);
          item.controls[index].get("cx").setValue(response.data.payload[0].cx);
          item.controls[index].get("cc").setValue(response.data.payload[0].cc);
          item.controls[index].get("add").setValue(response.data.payload[0].add);
          item.controls[index].get("ct").setValue(response.data.payload[0].ct);
          item.controls[index].get("et").setValue(response.data.payload[0].et);
          item.controls[index].get("tc").setValue(response.data.payload[0].tc);
          item.controls[index].get("wt").setValue(response.data.payload[0].wt);
          item.controls[index].get("remarks").setValue(response.data.payload[0].remarks);
          item.controls[index].get("vendorItemCatalogId").setValue(response.data.payload[0].vendorItemCatalogId);
        } else {
          this.loadingIndicator = false;
          item.controls[index].get("vendorItemCode").setValue('');
          item.controls[index].get("qty").setValue('');
          const option = {
            width: 300,
            displayTime: 3000,
            message: 'Barcode not match in selected itemcatalog',
            type: 'error'
            };
            notify(option);
          console.error(response);
        }
      })
      .catch((error) => {
        this.loadingIndicator = false;
        console.log(error);
      });
    } else {
      item.controls[index].get("cxtypeId").setValue(null);
      item.controls[index].get("cx").setValue(null);
      item.controls[index].get("cc").setValue(null);
      item.controls[index].get("add").setValue(null);
      item.controls[index].get("ct").setValue(null);
      item.controls[index].get("et").setValue(null);
      item.controls[index].get("tc").setValue(null);
      item.controls[index].get("wt").setValue(null);
      item.controls[index].get("remarks").setValue(null);
      item.controls[index].get("vendorItemCatalogId").setValue(null);
    }
  }

  
  setBarCodeRange(rangeData: any) {
    if (rangeData && rangeData.length > 0) {
      this.filterCxRangeList = [];
      this.cxRangeList.forEach((item) => {
        this.rangeData.forEach((range) => {
          if (item.cxValue == range.cx) {
            this.filterCxRangeList.push(item);
          }
        });
      });
    }
  }

  /**
   * @name saveStockRequest
   * @param {FormGroup} requestForm
   * @returns payload form cxtypedetail
   */
  saveStockRequest(requestForm: FormGroup) {
    this.loadingIndicator = true;
    if (requestForm.invalid) {
      return false;
    }
    this.requestForm.addControl('totalQTY',  new FormControl(this.getTotal, []));
    this.optSelected.emit(requestForm.value);
    setTimeout(() => {
      this.loadingIndicator = false;
    }, 300);
  }

  /**
   * @name clearField
   * @return void
   */
  clearField(): void {
    const item = (this.requestForm.get("sections") as FormArray)
      .at(0)
      .get("cxTypeDetail") as FormArray;
    while (item.length !== 0) {
      item.removeAt(0);
    }
    // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
    // while (item.length !== 0) {
    //   item.removeAt(0);
    // }
    // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
    // while (child.length !== 0) {
    //   child.removeAt(0);
    // }
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(0)
      .get("min")
      .setValue("");
    ((this.sectionsArray as FormArray).at(0).get("childern") as FormArray)
      .at(0)
      .get("max")
      .setValue("");
    // ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').setValue('');
    // ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue('');
  }
  /**
   * @description validateSubmitBtn
   * @returns bool
   */
  get validateSubmitBtn() {
    let val = false;
    const item = (this.requestForm.get("sections") as FormArray)
      .at(0)
      .get("cxTypeDetail") as FormArray;
    item.controls.forEach((element) => {
      // console.log(element.value);
      if (
        // element.value.vendorItemCode ||
        // element.value.add ||
        // element.value.cc ||
        // element.value.ct ||
        // element.value.cx ||
        // element.value.et ||
        // element.value.tc ||
        // element.value.wt ||
        element.value.qty
      ) {
        val = true;
      }
    });
    return val;
  }
  /**
   * @description destroy component
   */
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    // this.subscription.unsubscribe();
  }

  /**
   * @name generateGrid
   * @param {number} min
   * @param {number} max
   * @return grid
   */
  generateGrid(min: number, max: number) {
    for (let i = min; i < max; i++) {
      this.createCxTypeDetail(
        this.tempcxTypeDetail ? this.tempcxTypeDetail[i] : ""
      );
      //this.validateSubmitBtn;
    }
  }

  /**
   * @name getAllCxTypes
   * @description item cx type
   * @method GET
   * @returns Promise
   */
  getAllCxTypes() {
    this.loadingIndicator = true;
    this.sharedService
      .getAllCxTypes()
      .then((response) => {
        if (response.data.completed) {
          this.cxList = response.data.payload;
          this.loadingIndicator = false;
        }
      })
      .catch((err) => {
        this.loadingIndicator = false;
        console.log("err ", err);
      });
  }
  /**
   * @name getCX
   * @param {number} range1
   * @param {number} range2
   * @returns
   * @memberof SharedService
   */
  getCX(range1: number, range2: number) {
    this.loadingIndicator = true;
    this.sharedService
      .getCX(range1, range2)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.cxRangeList = list;
          } else {
            if (response.data.payload.active) {
              this.cxRangeList = response.data.payload;
            }
          }
          this.setBarCodeRange(this.rangeData);
          this.loadingIndicator = false;
        }
      })
      .catch((err) => {
        this.loadingIndicator = false;
        console.log("err ", err);
      });
  }
  /**
   * @name addRows
   * @return number of rows
   * @description modal popup
   */
  addRows() {
    const dialogRef = this.dialog.open(DialogAddRows, {
      width: "250px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed", result);
      if (result.rows) {
        for (let i = 0; i < parseInt(result.rows); i++) {
          let data = {
            isBarCodeEnable: true
          };
          this.createCxTypeDetail(data);

          // this.updatedMaxVal('increment');
        }
        this.setBarCodeRange(this.rangeData);
      }
    });
  }

  getItemCatalogDetail(cxValue: number, index: number) {
    if (cxValue && this.itemCatalogGroupId) {
      this.loadingIndicator = true;
      const item = (this.requestForm.get("sections") as FormArray)
        .at(0)
        .get("cxTypeDetail") as FormArray;
      this.sharedService
        .getItemCatalogDetail(this.itemCatalogGroupId, cxValue)
        .then((response) => {
          if (response.data.completed && response.data.payload) {
            // reset
            item.controls[index].get("cxtypeId").setValue(null);
            item.controls[index].get("cc").setValue(null);
            item.controls[index].get("add").setValue(null);
            item.controls[index].get("ct").setValue(null);
            item.controls[index].get("et").setValue(null);
            item.controls[index].get("tc").setValue(null);
            item.controls[index].get("wt").setValue(null);
            item.controls[index].get("remarks").setValue(null);
            item.controls[index].get("vendorItemCatalogId").setValue(null);
            // end
            item.controls[index]
              .get("cxtypeId")
              .setValue(response.data.payload.cxtypeId);
            item.controls[index].get("cc").setValue(response.data.payload.cc);
            item.controls[index].get("add").setValue(response.data.payload.add);
            item.controls[index].get("ct").setValue(response.data.payload.ct);
            item.controls[index].get("et").setValue(response.data.payload.et);
            item.controls[index].get("tc").setValue(response.data.payload.tc);
            item.controls[index].get("wt").setValue(response.data.payload.wt);
            item.controls[index].get("remarks").setValue(response.data.payload.remarks);
            item.controls[index].get("vendorItemCatalogId").setValue(response.data.payload.vendorItemCatalogId);
          } else {
            // reset
            item.controls[index].get("cxtypeId").setValue(null);
            item.controls[index].get("cc").setValue(null);
            item.controls[index].get("add").setValue(null);
            item.controls[index].get("ct").setValue(null);
            item.controls[index].get("et").setValue(null);
            item.controls[index].get("tc").setValue(null);
            item.controls[index].get("wt").setValue(null);
            item.controls[index].get("remarks").setValue(null);
            item.controls[index].get("vendorItemCatalogId").setValue(null);
            // end
          }
          this.loadingIndicator = false;
        })
        .catch((err) => {
          this.loadingIndicator = false;
          console.log("err ", err);
        });
    } else {
      const item = (this.requestForm.get("sections") as FormArray)
        .at(0)
        .get("cxTypeDetail") as FormArray;
      // reset
      item.controls[index].get("cxtypeId").setValue(null);
      item.controls[index].get("cc").setValue(null);
      item.controls[index].get("add").setValue(null);
      item.controls[index].get("ct").setValue(null);
      item.controls[index].get("et").setValue(null);
      item.controls[index].get("tc").setValue(null);
      item.controls[index].get("wt").setValue(null);
      item.controls[index].get("remarks").setValue(null);
      item.controls[index].get("vendorItemCatalogId").setValue(null);
      // end
    }
  }

  /**
   * @name incrementMaxVal
   * @returns updated max value
   */
  updatedMaxVal(label: string) {
    const val = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
    if (label === "increment") {
      return ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(0)
        .get("max")
        .setValue(parseInt(val) + 1);
    }
    if (label === "decrement") {
      return ((this.sectionsArray as FormArray)
        .at(0)
        .get("childern") as FormArray)
        .at(0)
        .get("max")
        .setValue(parseInt(val) - 1);
    }
  }

  /**
   * @name showRemarks
   * @param {FormGroup} section
   * @param {number} index
   * @returns void
   */
  showRemarks(section: FormGroup, index: number) {
    this.userRemarks = null;
    this.popupTarget = '#comment-' + index;
    this.actionSheetVisible = true;
    this.userRemarks = section.value.cxTypeDetail[index].remarks;
  }
  /**
   * @name maxValue
   * @readonly
   * @memberof InventoryCXGridComponent
   */
  get maxValue() {
    return ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
  }

  /**
   * @name validateCxValue
   * @param {*} value
   * @param {*} index
   * @memberof InventoryCXGridComponent
   */
  validateCxValue(value, index) {
    let max = ((this.sectionsArray as FormArray)
      .at(0)
      .get("childern") as FormArray)
      .at(0)
      .get("max").value;
    let item = (this.requestForm.get("sections") as FormArray)
      .at(0)
      .get("cxTypeDetail") as FormArray;
    if (value > max) {
      this.userService.showError("value Not allow greater than range value");
      item.controls[index].get("cx").setValue(null);
      $("table tbody tr td input")
        .find("input-" + index)
        .addClass("error");
    } else {
      $("table tbody tr td input")
        .find("input-" + index)
        .removeClass("error");
    }
  }
  /**
   * @name removeIndex
   * @param index
   * @return void
   * @description removing row index
   */
  removeIndex(index) {
    const item = (this.requestForm.get("sections") as FormArray)
      .at(0)
      .get("cxTypeDetail") as FormArray;
    item.removeAt(index);
    this.updatedMaxVal("decrement");
    // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
  }

  /**
   * @name getTotal
   * @param {Array} list
   * @param {key} string
   * @returns total
   */
 get  getTotal() {
    let tot = 0;
    const item = (this.requestForm.get("sections") as FormArray)
      .at(0)
      .get("cxTypeDetail") as FormArray;
    item.value.forEach((item) => {
      let val = parseInt(item.qty ? item.qty : 0);
        // parseInt(item.qty ? item.qty : 0) +
        // parseInt(item.cc ? item.cc : 0) +
        // parseInt(item.add ? item.add : 0) +
        // parseInt(item.ct ? item.ct : 0) +
        // parseInt(item.et ? item.et : 0) +
        // parseInt(item.tc ? item.tc : 0) +
        // parseInt(item.wt ? item.wt : 0);
      tot += val;
    });
    return  tot;
  }
  fixVerticalTabindex(selector) {
    if (typeof selector == "undefined") {
      selector = ".reset-tabindex";
    }
    let tabindex = 1;
    $(selector).each((i, tbl) => {
      $(tbl)
        .find("tr")
        .first()
        .find("td")
        .each((clmn, el) => {
          $(tbl)
            .find("tr td:nth-child(" + (clmn + 1) + ") div input")
            .each((j, input) => {
              $(input).attr("placeholder", tabindex);
              $(input).attr("tabindex", tabindex++);
            });
        });
    });
  }
  toggleStock(label: string) {
    switch (label) {
        case 'previous':
            this.previousStock = !this.previousStock;
            this.getAllAvailableStock(this.companyBranchId, this.itemCatalogGroupId, false);
            break;
         case 'current':
            this.currentStock = !this.currentStock;
            // this.setCurrentStock(this.dataSource.rangeStockListPayload);
          break;
    }
 }
    /**
   * @name getAllAvailableStock
   * @param {number} companyBranchId
   * @param {number} itemCatalogGroupId
   * @param {number} isPositiveRange
   * @returns
   * @memberof SharedService
   */
  getAllAvailableStock(companyBranchId: number, itemCatalogGroupId: number, isPositiveRange: boolean) {
    this.loadingAllAvailableStock = true;
    this.sharedService.getAllAvailableStock(companyBranchId, itemCatalogGroupId, isPositiveRange)
        .then((response: any) => {
            if (response.data.completed) {
            // this.checkRange();
            console.log('response', response)
            this.loadingAllAvailableStock = false;
            // this.setAvailableStock(response.data.payload.availableStock);
            } else {
                this.loadingAllAvailableStock = false;
                console.error(response);
            }
        })
        .catch(error => {
            this.loadingAllAvailableStock = false;
            console.log(error);

        });

 }
}
