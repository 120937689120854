import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {MatSnackBar} from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StorageService } from '../../../../services/branchstorage.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import axios from 'axios';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  passwordMismatch: boolean;
  loadingIndicator: boolean;
  popupVisible: boolean;
  public userImage = '../assets/img/users/user.jpg';
  public loggedUserImage = 'https://dehlawi-asset.s3.ap-south-1.amazonaws.com/logo.png';
  public loggedUserName = '';
  updatePasswordForm: FormGroup;
  passwordPattern: any = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
  @ViewChild('saveSwal') private saveSwal: SwalComponent;
  constructor(private formBuilder: FormBuilder, public router: Router, private _snackBar: MatSnackBar, private bService: StorageService) { }

  ngOnInit() {
    // update password form initialization
    this.loggedUserName = this.bService.getUserInfo.employeeName;
    if (this.bService.getUserInfo.employeePicture && this.bService.getUserInfo.employeePicture !== null) {
      this.loggedUserImage = this.bService.getUserInfo.employeePicture;
    }
    this.updatePasswordForm = this.formBuilder.group({
      currentPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
  }

  /**
   * @name updatePasswordContent
   * @description display update password pop up
  */
  updatePasswordContent () {
    this.popupVisible = true;
    if(this.updatePasswordForm.get('currentPassword').value || this.updatePasswordForm.get('newPassword').value || this.updatePasswordForm.get('confirmPassword').value)
    {
      this.updatePasswordForm.reset();
    } 
 }

  /**
   * @name updatePassword
   * @description updates user password through api
  */
  updatePassword(data){
    this.loadingIndicator = true;
    this.passwordMismatch = false;
    if(data.value.newPassword != data.value.confirmPassword){
      this.passwordMismatch = true;
      this.loadingIndicator = false;
      return;
    }
    axios.post(`Account/UpdatePassword`, {id: JSON.parse(localStorage.getItem('userData')).id, currentPassword: data.value.currentPassword, newPassword: data.value.newPassword})
    .then((res: any) => {
      if (res.data.completed) {
        this.popupVisible = false;
        this.loadingIndicator = false;
        this.saveSwal.swalOptions = {
          icon: 'success',
          titleText: res.data.message,
          showConfirmButton: false,
          showCancelButton: false,
          timer: 1500
        };
        this.saveSwal.fire();
        this.updatePasswordForm.reset();
      }
      else{
        this.loadingIndicator = false;
        const option = {
          width: 300,
          displayTime: 2000,
          message: res.data.message,
          type: 'error'
        };
        notify(option);
      }
    })
    .catch(err => {
      console.log('true')
      const option = {
        width: 300,
        displayTime: 3000,
        // message: err.error,
        message: "Passwords must be at least 6 characters and contain at least one of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (!@#$%^&*)",
        type: 'error'
      };
      notify(option);
      this.loadingIndicator = false;
    });
  }


  logOutUser () {
    console.info('logged out');
    localStorage.removeItem('userData');
    localStorage.removeItem('userToken');
    localStorage.removeItem('selectedCustomerBranch');
    localStorage.removeItem('userRights');
    localStorage.removeItem('vendorFilters');
    localStorage.removeItem('rxgrouping');
    this.bService.setBranch = null;
    this._snackBar.open('User successfully logged out of application', '', {
      duration: 500
    });
    this.router.navigate(['/authentication/login']);
  }
}
