import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import DataSource from 'devextreme/data/data_source';
import { SharedService } from './../shared.service';
import notify from 'devextreme/ui/notify';

declare var $: any;
@Component({
  selector: 'app-filter-single-item',
  templateUrl: './filter-single-item.component.html',
  styleUrls: ['./filter-single-item.component.scss']
})
export class FilterSingleItemComponent implements OnInit, OnChanges {
  
@Input() heading: string;
@Input() brandMasterId: number;
@Input() itemTypeMasterId: number;
@Output() outPowerRangeSelected: EventEmitter<any> = new EventEmitter();
@Input() isEdit: any;
@Output() outRefreshEvent: EventEmitter<any> = new EventEmitter();
@Input() resetPowerRange: boolean;
@Input() priceGroup: boolean;
type: string;
loading: boolean;

filterForm: FormGroup;

allMaterials: any[];
itemTypesList: any[];
focalIdsList: any[];
brandsList: any[];
subBrandsList: any[];
itemCatalogList: any;
priceGroupList: any;
vendorList: any[];
powerRangeList: any[];
powerRangeSelected: any;

loadingItemTypeMaster: boolean;
loadingMaterials: boolean;
loadingFocal: boolean;
loadingBrand: boolean;
loadingSubBrand: boolean;
loadingVendor: boolean;
loadingItemCatalog: boolean;
loadingPowerRange: boolean;
indexList: any[];
  loadingIndex: boolean;
  constructor(private _sharedService: SharedService) { }

  ngOnInit() {
    this.allMaterials = [];
    this.itemTypesList = [];
    this.focalIdsList = [];
    this.brandsList = [];
    this.subBrandsList = [];
    this.itemCatalogList = [];
    this.priceGroupList = [];
    this.vendorList = [];

    this.type = 'add';

    this.filterForm = new FormGroup({
      itemCatalogGroupId: new FormControl(0, [Validators.required]),
      // brandMasterId: new FormControl('', []),
      // itemTypeMasterId: new FormControl(2, []),
      materialId: new FormControl(0, [Validators.required]),
      focalId: new FormControl(0, [Validators.required]),
      powerRange: new FormControl(0,[Validators.required]),
      subBrandMasterId: new FormControl(0, []),
      indexId: new FormControl(0, []),
      vendorId: new FormControl(0, []),
      active: new FormControl(true, [])
    });

    this.getAllMaterials();
    this.getFocalByItemTypeMasterId(2);
    // this.getSubBrandByItemTypeMasterIdANDbrandMasterId(this.itemTypeMasterId, this.brandMasterId);
    // this.filterForm.get('brandMasterId').setValue(this.brandMasterId);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.resetPowerRange && changes.resetPowerRange.currentValue) {
        this.filterForm.get('powerRange').setValue(null);
    }
      this.getAllSubBrandMasterByBrandMasterId(this.brandMasterId);
  }
  /**
   * @name getAllMaterials
   * @returns Promise
   * @description fetch all item
   */
  getAllMaterials() {
    this.loadingMaterials = true;
    this._sharedService
      .getAllMaterials()
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.allMaterials = list;
          } else {
            if (response.data.payload.active) {
              this.allMaterials = response.data.payload;
            }
          }
          this.loadingMaterials = false;
        }
      })
      .catch(err => {
        this.loadingMaterials = false;
        console.error('Error:  ', err);
      });
  }

  /**
   * @name getAllBrandMasters
   * @returns Promise
   * @description fetch all item
   */
  getAllIndexByMaterialId(materialId: number) {
    if (materialId) {
      this.indexList = [];
      this.loadingIndex = true;
      this._sharedService
        .getAllIndexByMaterialId(materialId)
        .then((response) => {
          if (response.data.completed) {
            if (response.data.payload.length > 0) {
              const list = [];
              response.data.payload.forEach((element) => {
                if (element.active) {
                  list.push(element);
                }
              });
              this.indexList = list;
            } else {
              if (response.data.payload.active) {
                this.indexList = response.data.payload;
              }
            }
            this.loadingIndex = false;
              this.filterAllItemCatalogs(this.type);
          }
        })
        .catch((error) => {
          this.loadingIndex = false;
          const option = {
            width: 300,
            displayTime: 5000,
            message: error.message,
            type: "error",
          };
          console.log(error);
          notify(option);
        });
    } else {
      this.indexList = [];
    }
  }
  /**
  * @name getItemTypeByMaterialId
  * @param {event}  materialId
  * @return itemTypesList
  * @description fetch item type list by material Id
  * 
  */
//  getItemTypeByMaterialId(materialId, type?: string) {
//   if (materialId) {
//     this.itemTypesList = [];
//     this.focalIdsList = [];
//     this.brandsList = [];
//     this.subBrandsList = [];
//     if (type == 'add') {
//       this.filterForm.get('itemTypeMasterId').setValue('');
//       this.filterForm.get('focalId').setValue('');
//       this.filterForm.get('brandMasterId').setValue('');
//       this.filterForm.get('subBrandMasterId').setValue('');
//     }
//     this.loadingItemTypeMaster = true;
//     this._sharedService
//       .getItemTypeByMaterialId(JSON.parse(
//         localStorage.getItem('selectedCustomerBranch')
//       ).countryId, materialId)
//       .then(response => {
//         if (response.data.completed) {
//           if (response.data.payload.length > 0) {
//             const list = [];
//             response.data.payload.forEach(element => {
//               if (element.active) {
//                 list.push(element);
//               }
//             });
//             this.itemTypesList = list;
//           } else {
//             if (response.data.payload.active) {
//               this.itemTypesList = response.data.payload;
//             }
//           }
//         } else {
//           const option = {
//             width: 300,
//             displayTime: 3000,
//             message: response.data.message,
//             type: 'error'
//           };
//           notify(option);
//         }
//         this.loadingItemTypeMaster = false;
//         this.filterAllItemCatalogs(this.type);
//       })
//       .catch(error => {
//         this.loadingItemTypeMaster = false;
//         const option = {
//           width: 300,
//           displayTime: 3000,
//           message: error.message,
//           type: 'error'
//         };
//         notify(option);
//       });
//   } else {
//     this.brandsList = [];
//     this.itemTypesList = [];
//     this.itemCatalogList = [];
//     // this.barCodeList = [];
//      this.powerRangeSelected = {};
//   }
// }
 /**
 * @name getFocalByItemTypeMasterId
 * @param {event}  itemTypeMasterId
 * @return focalIdsList
 * @description fetch data by id
 * 
 */
getFocalByItemTypeMasterId(itemTypeMasterId, type?: string) {
  if (itemTypeMasterId) {
    this.focalIdsList = [];
    this.brandsList = [];
    this.subBrandsList = [];
    if (itemTypeMasterId == 3) {
      this.filterForm.get("vendorId").setValue("");
      // this.filterForm.get("lensSideId").setValue("");
      this.filterForm.get("vendorId").setValidators([Validators.required]);
    } else {
      this.filterForm.get("vendorId").clearValidators();
    }
    this.filterForm.get("vendorId").updateValueAndValidity();
    if (type == 'add') {
      this.filterForm.get('focalId').setValue('');
      // this.filterForm.get('brandMasterId').setValue('');
      this.filterForm.get('subBrandMasterId').setValue('');
    }
    this.loadingFocal = true;
    this._sharedService
      .getFocalByItemTypeMasterId(itemTypeMasterId)
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.focalIdsList = list;
          } else {
            if (response.data.payload.active) {
              this.focalIdsList = response.data.payload;
            }
          }
        } else {
          const option = {
            width: 300,
            displayTime: 3000,
            message: response.data.message,
            type: 'error'
          };
          notify(option);
        }
        this.loadingFocal = false;
        let event = {
          event: true
        };
        this.filterAllItemCatalogs(event, this.type);
      })
      .catch(error => {
        this.loadingFocal = false;
        const option = {
          width: 300,
          displayTime: 3000,
          message: error.message,
          type: 'error'
        };
        notify(option);
      });
  } else {
    this.focalIdsList = [];
    this.itemCatalogList = [];
    this.priceGroupList = [];
    // this.barCodeList = [];
    this.powerRangeSelected = {};
  }
}

 /**
   * @name getBrandsForItemCatalog
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} materialId
   * @param {string} type
   * @return focalIdsList
   * @description fetch data by id
   * 
   */
  // getBrandsForItemCatalog(itemTypeMasterId: number, focalId: number, materialId: number, type: any) {

  //   if (itemTypeMasterId && focalId && materialId) {
  //     this.brandsList = [];
  //     this.subBrandsList = [];
  //     if (type == 'add') {
  //       this.filterForm.get('brandMasterId').setValue('');
  //       this.filterForm.get('subBrandMasterId').setValue('');
  //     }
  //     this.loadingBrand = true;
  //     this._sharedService
  //       .getBrandsForItemCatalog(itemTypeMasterId, focalId, materialId)
  //       .then(response => {
  //         if (response.data.completed) {
  //           if (response.data.payload.length > 0) {
  //             const list = [];
  //             response.data.payload.forEach(element => {
  //               if (element.active) {
  //                 list.push(element);
  //               }
  //             });
  //             this.brandsList = list;
  //           } else {
  //             if (response.data.payload.active) {
  //               this.brandsList = response.data.payload;
  //             }
  //           }
  //         } else {
  //           const option = {
  //             width: 300,
  //             displayTime: 3000,
  //             message: response.data.message,
  //             type: 'error'
  //           };
  //           notify(option);
  //         }
  //         this.loadingBrand = false;
  //           this.filterAllItemCatalogs(this.type);
  //       })
  //       .catch(error => {
  //         this.loadingBrand = false;
  //         const option = {
  //           width: 300,
  //           displayTime: 3000,
  //           message: error.message,
  //           type: 'error'
  //         };
  //         notify(option);
  //       });
  //   } else {
  //     this.brandsList = [];
  //     this.subBrandsList = [];
  //     this.itemCatalogList = [];
  //   }
  // }

/**
   * @name getAllSubBrandMasterByBrandMasterId
   * @param {number} brandMasterId
   * @return focalIdsList
   * @description fetch data by id
   *
   */
 getAllSubBrandMasterByBrandMasterId(brandMasterId, type?: string) {

  if (brandMasterId) {
    this.subBrandsList = [];
    if (type == 'add') {
      this.filterForm.get('subBrandMasterId').setValue('');
    }
    this.loadingSubBrand = true;
    this._sharedService
      .getAllSubBrandMasterByBrandMasterId(brandMasterId)
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach(element => {
              if (element.active) {
                list.push(element);
              }
            });
            this.subBrandsList = list;
          } else {
            if (response.data.payload.active) {
              this.subBrandsList = response.data.payload;
            }
          }
        } else {
          const option = {
            width: 300,
            displayTime: 3000,
            message: response.data.message,
            type: 'error'
          };
          notify(option);
        }
        this.loadingSubBrand = false;
        let event = {
          event: true
        };
        this.filterAllItemCatalogs(event, this.type);
      })
      .catch(error => {
        this.loadingSubBrand = false;
        const option = {
          width: 300,
          displayTime: 3000,
          message: error.message,
          type: 'error'
        };
        notify(option);
      });
  } else {
    this.subBrandsList = [];
  }

}

/**
  * @name getVendorByBrandMasterID
  * @param {number}  brandMasterId
  * @return vendor list
  * @description fetch data by id
  * 
  */
 getVendorByBrandMasterID(brandMasterId: number, type?: string) {
  if (brandMasterId) {
    this.vendorList = [];
    if (type == "add") {
      this.filterForm.get("vendorId").setValue("");
    }
    this.loadingVendor = true;
    this._sharedService
      .getVendorByBrandMasterID(brandMasterId)
      .then((response) => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            const list = [];
            response.data.payload.forEach((element) => {
              if (element.active) {
                list.push(element);
              }
            });
            this.vendorList = list;
          } else {
            if (response.data.payload.active) {
              this.vendorList = response.data.payload;
            }
          }
        } else {
          const option = {
            width: 300,
            displayTime: 3000,
            message: response.data.message,
            type: "error",
          };
          notify(option);
        }
        let event = {
          event: true
        };
        this.filterAllItemCatalogs(event, this.type);
        this.loadingVendor = false;
      })
      .catch((error) => {
        this.loadingVendor = false;
        const option = {
          width: 300,
          displayTime: 3000,
          message: error.message,
          type: "error",
        };
        notify(option);
      });
  }
}

 /**
   * @name filterAllItemCatalogs
   * @param {event}  itemTypeMasterId
   * @param {number} brandMasterId
   * @return subBrandsList
   * @description fetch data by id
   */
  filterAllItemCatalogs(event: any, type?: string, subBrandMasterId?: number) {
    if (event && event.event) {
      if (this.itemTypeMasterId == 3 || this.itemTypeMasterId == 4) {
        // this.filterForm.get("barCodeRangeId").clearValidators();
        // this.filterForm.get("barCodeRangeId").updateValueAndValidity();
        if (
          this.filterForm.get("vendorId").value &&
          this.filterForm.get("materialId").value &&
          this.filterForm.get("focalId").value &&
          this.itemTypeMasterId &&
          this.brandMasterId
        ) {
          const payload = {
            materialId: this.filterForm.get("materialId").value
              ? this.filterForm.get("materialId").value
              : 0,
            focalId: this.filterForm.get("focalId").value
              ? this.filterForm.get("focalId").value
              : 0,
            brandMasterId: this.brandMasterId
              ? this.brandMasterId
              : 0,
            subBrandMasterId: subBrandMasterId ? subBrandMasterId : 0,
            vendorId: this.filterForm.get("vendorId").value
              ? this.filterForm.get("vendorId").value
              : 0,
            itemTypeMasterId: this.itemTypeMasterId
              ? this.itemTypeMasterId
              : 0,
              indexId: this.filterForm.get("indexId").value
              ? this.filterForm.get("indexId").value
              : 0
          };
          this.itemCatalogList = [];
          this.priceGroupList = [];
          
          // this.barCodeList = [];
  
          if (type == "add") {
            this.filterForm.get("itemCatalogGroupId").setValue("");
             this.powerRangeSelected = {};
          }
          this.loadingItemCatalog = true;
          this._sharedService
            .getAllItemCatalogGroup(payload)
            .then((response) => {
              if (response.data.completed) {
                if (response.data.payload.length > 0) {
                  const list = [];
                  response.data.payload.forEach((element) => {
                    if (element.active) {
                      list.push(element);
                    }
                  });
                  if (this.priceGroup) {
                    this.priceGroupList = new DataSource({
                      store: list,
                    paginate: true,
                    pageSize: 10
                    });
                  } else {
                    this.itemCatalogList = new DataSource({
                      store: list,
                    paginate: true,
                    pageSize: 10
                    });
                  }
                  
                } else {
                  if (response.data.payload.active) {
                    if (this.priceGroup) {
                      this.priceGroupList = new DataSource({
                        store: response.data.payload,
                      paginate: true,
                      pageSize: 10
                      });
                    } else {
                      this.itemCatalogList = new DataSource({
                        store: response.data.payload,
                      paginate: true,
                      pageSize: 10
                      });
                    }
                  }
                }
              } else {
                const option = {
                  width: 300,
                  displayTime: 3000,
                  message: response.data.message,
                  type: "error",
                };
                notify(option);
              }
              this.loadingItemCatalog = false;
            })
            .catch((error) => {
              this.loadingItemCatalog = false;
              const option = {
                width: 300,
                displayTime: 3000,
                message: error.message,
                type: "error",
              };
              notify(option);
            });
        }
      } else {
        if (
          this.filterForm.get("materialId").value &&
          this.filterForm.get("focalId").value &&
          this.itemTypeMasterId &&
          this.brandMasterId
        ) {
          const payload = {
            materialId: this.filterForm.get("materialId").value
              ? this.filterForm.get("materialId").value
              : 0,
            focalId: this.filterForm.get("focalId").value
              ? this.filterForm.get("focalId").value
              : 0,
            brandMasterId: this.brandMasterId
              ? this.brandMasterId
              : 0,
            subBrandMasterId: subBrandMasterId ? subBrandMasterId : 0,
            vendorId: this.filterForm.get("vendorId").value
              ? this.filterForm.get("vendorId").value
              : 0,
            itemTypeMasterId: this.itemTypeMasterId
              ? this.itemTypeMasterId
              : 0,
              indexId: this.filterForm.get("indexId").value
              ? this.filterForm.get("indexId").value
              : 0
          };
          this.itemCatalogList = [];
          this.priceGroupList = [];
          // this.barCodeList = [];
  
          if (type == "add") {
            this.filterForm.get("itemCatalogGroupId").setValue("");
             this.powerRangeSelected = {};
          }
          this.loadingItemCatalog = true;
          this._sharedService
            .getAllItemCatalogGroup(payload)
            .then((response) => {
              if (response.data.completed) {
                if (response.data.payload.length > 0) {
                  const list = [];
                  response.data.payload.forEach((element) => {
                    if (element.active) {
                      list.push(element);
                    }
                  });
                  if (this.priceGroup) {
                    this.priceGroupList = list;
                  } else {
                    this.itemCatalogList = list;
                  }
                } else {
                  if (response.data.payload.active) {
                    if (this.priceGroup) {
                      this.priceGroupList = response.data.payload;
                    } else {
                      this.itemCatalogList = response.data.payload;
                    }
                  }
                }
              } else {
                const option = {
                  width: 300,
                  displayTime: 3000,
                  message: response.data.message,
                  type: "error",
                };
                notify(option);
              }
              this.loadingItemCatalog = false;
            })
            .catch((error) => {
              this.loadingItemCatalog = false;
              const option = {
                width: 300,
                displayTime: 3000,
                message: error.message,
                type: "error",
              };
              notify(option);
            });
        }
      }
    }
   
  }
  

 /**
   *@name getPowerRanges
   * @param {number} brandMasterId
   * @param {number} materialId
   * @param {number} subBrandMasterId
   * @param {number} focalId
   * @param {number} itemTypeMasterId
   * @param {number} indexId
   * @param {number} itemCatalogGroupId
   * @returns
   * @memberof SalesService
   */
  getPowerRanges(event) {
    if (event && event.event) {
      const filter = {
        materialId: this.filterForm.get("materialId").value
            ? this.filterForm.get("materialId").value
            : 0,
          focalId: this.filterForm.get("focalId").value
            ? this.filterForm.get("focalId").value
            : 0,
          brandMasterId: this.brandMasterId
            ? this.brandMasterId
            : 0,
          subBrandMasterId: this.filterForm.get("subBrandMasterId").value
          ? this.filterForm.get("subBrandMasterId").value
          : 0,
          vendorId: this.filterForm.get("vendorId").value
            ? this.filterForm.get("vendorId").value
            : 0,
          itemTypeMasterId: this.itemTypeMasterId
            ? this.itemTypeMasterId
            : 0,
            indexId: this.filterForm.get("indexId").value
            ? this.filterForm.get("indexId").value
            : 0,
            itemCatalogGroupId: this.priceGroup 
            ? 0
            : this.filterForm.get("itemCatalogGroupId").value,
      }
      this.loadingPowerRange = true;
    this.powerRangeList = [];
    this.filterForm.get('powerRange').setValue('');
    this._sharedService
      .getPowerRanges(filter)
      .then(response => {
        if (response.data.completed) {
          if (response.data.payload.length > 0) {
            this.powerRangeList = response.data.payload;
            this.powerRangeList.map((item, index) => {
              item['rowIndex'] = index + 1;
            });
          }
        }
        this.loadingPowerRange = false;
      })
      .catch(err => {
        this.loadingPowerRange = false;
        console.error('Error:  ', err);
      });
    }
  }

  /**
   * @name getByItemCatalogId
   * @param {number} itemCatalogGroupId
   * @returns filter list
   * @memberof salesService
   */
   getByItemCatalogId(itemCatalogGroupId: number) {
     if (itemCatalogGroupId) {
      this.loadingPowerRange = true;
      this.powerRangeList = [];
      this.filterForm.get('powerRange').setValue('');
      this._sharedService
        .getByItemCatalogId(itemCatalogGroupId)
        .then(response => {
          if (response.data.completed) {
            if (response.data.payload) {
              this.powerRangeList = [response.data.payload];
              this.powerRangeList.map((item, index) => {
                item['rowIndex'] = index + 1;
              });
            }
          }
          this.loadingPowerRange = false;
        })
        .catch(err => {
          this.loadingPowerRange = false;
          console.error('Error:  ', err);
        });
     }
}

  /**
   * @name pushPowerRange
   * @return singal object
   */
  pushPowerRange(event) {
    console.log('pushPowerRange', event)
    if (event && event.selectedItem) {
      this.powerRangeSelected = event.selectedItem;
    } else {
      this.powerRangeSelected = {};
    }
  }

  /**
   * @name submitData
   * @param form FormControl
   * @returns void
   */
  submitData(form: FormGroup) {
    if (this.filterForm.invalid) {
      $('app-filter-single-item').find('form').removeClass('hideError');
      return;
    }
    this.loading = true;
    this.outPowerRangeSelected.emit(this.powerRangeSelected);
    this.powerRangeSelected = {};
    this.loading = false;
  }


/**
 * @name refreshData
 * @return void
 */

  refreshData() {
    this.outRefreshEvent.emit();
  }

}
