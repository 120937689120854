<div class="form-cyl-container mt-3">
    <div class="">
      <form [formGroup]="requestForm" >
        <ng-container class="row mt-3" formArrayName="sections">
          <ng-container
            *ngFor="let section of sectionsArray.controls; let i = index"
          >
            <ng-container [formGroupName]="i">
              <div class="row">
                <ng-container formArrayName="childern">
                  <div class="col-sm-6"
                    [formGroupName]="j"
                    *ngFor="let item of getChildern(section); let j = index"
                  >

                  <div class="card">
                    <div class="card-header">
                        {{ item.get("name").value }}
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6">
                              <div class="dx-field" *ngIf="item.get('name').value == 'SPH'">
                                <div>MIN<span>*</span>:</div>
                                <dx-select-box
                                  [inputAttr]="{ autocomplete: 'off' }"
                                  class="enter-keypress-focus-input"
                                  formControlName="min"
                                  [items]="sphRangeList"
                                  [readOnly]="sphRangeList?.length == 0"
                                  placeholder="Select MIN *"
                                  displayExpr="sphValue"
                                  valueExpr="sphValue"
                                  [showClearButton]="true"
                                  [showDataBeforeSearch]="false"
                                  deferRendering="false"
                                  [searchEnabled]="true"
                                  >
                                  <dx-validator>
                                    <dxi-validation-rule type="required" message="Min required">
                                    </dxi-validation-rule>
                                  </dx-validator>
                                </dx-select-box>
                              </div>
                              <div class="dx-field" *ngIf="item.get('name').value == 'ADD'">
                                <div>ADD<span>*</span>:</div>
                                <dx-select-box
                                  [inputAttr]="{ autocomplete: 'off' }"
                                  class="enter-keypress-focus-input"
                                  formControlName="min"
                                  [items]="addfRangeList"
                                  [readOnly]="addfRangeList?.length == 0"
                                  placeholder="Select ADD *"
                                  displayExpr="addfValue"
                                  valueExpr="addfValue"
                                  [showClearButton]="true"
                                  [showDataBeforeSearch]="false"
                                  deferRendering="false"
                                  [searchEnabled]="true"
                                >
                                  <dx-validator>
                                    <dxi-validation-rule
                                      type="required"
                                      message="Min is required"
                                    ></dxi-validation-rule>
                                  </dx-validator>
                                </dx-select-box>
                              </div>
                                <!-- <div class="dx-field">
                                    <dx-number-box [inputAttr]="{ autocomplete: 'off' }"  
                                    [readOnly]="toggleGenerateBtn()"
                                    [step]="0.25"
                                    placeholder="Min"
                                      formControlName="min"
                                >
                                <dx-validator>
                                    <dxi-validation-rule 
                                        type="required"
                                        message="Min required">
                                    </dxi-validation-rule>
                                    <dxi-validation-rule type="range" message="Negative sign not allowed" [min]="0" [max]="99999"></dxi-validation-rule> 
                                    </dx-validator>
                              </dx-number-box>
                                
                                </div> -->
                                
                            </div>
                            <div class="col-sm-6">
                              <div class="dx-field" *ngIf="item.get('name').value == 'SPH'">
                                <div>MAX<span>*</span>:</div>
                                <dx-select-box
                                  [inputAttr]="{ autocomplete: 'off' }"
                                  class="enter-keypress-focus-input"
                                  formControlName="max"
                                  [items]="sphRangeList"
                                  [readOnly]="sphRangeList?.length == 0"
                                  placeholder="Select MAX *"
                                  displayExpr="sphValue"
                                  valueExpr="sphValue"
                                  [showClearButton]="true"
                                  [showDataBeforeSearch]="false"
                                  deferRendering="false"
                                  [searchEnabled]="true"
                                  >
                                  <dx-validator>
                                    <dxi-validation-rule type="required" message="Max required">
                                    </dxi-validation-rule>
                                  </dx-validator>
                                </dx-select-box>
                              </div>
                              <div class="dx-field" *ngIf="item.get('name').value == 'ADD'">
                                <div>MAX<span>*</span>:</div>
                                <dx-select-box
                                [inputAttr]="{ autocomplete: 'off' }"
                                class="enter-keypress-focus-input"
                                formControlName="max"
                                [items]="addfRangeList"
                                [readOnly]="addfRangeList?.length == 0"
                                placeholder="Select MAX *"
                                displayExpr="addfValue"
                                  valueExpr="addfValue"
                                deferRendering="false"
                                [showClearButton]="true"
                                [showDataBeforeSearch]="false"
                                [searchEnabled]="true">
                                  <dx-validator>
                                    <dxi-validation-rule type="required" message="Max required">
                                    </dxi-validation-rule>
                                  </dx-validator>
                                </dx-select-box>
                              </div>
                              <!-- <div class="dx-field">
                                  <dx-number-box [inputAttr]="{ autocomplete: 'off' }" 
                                  [step]="0.25"
                                  placeholder="Max"
                                    formControlName="max"
                                    [readOnly]="toggleGenerateBtn()"
                              >
                              <dx-validator>
                                  <dxi-validation-rule 
                                      type="required"
                                      message="Max required">
                                  </dxi-validation-rule>
                                  <dxi-validation-rule type="range" message="Negative sign not allowed" [min]="0" [max]="99999"></dxi-validation-rule> 
                                  </dx-validator>
                            </dx-number-box>
                              </div> -->
                            </div>
                            <!-- <div class="col-sm-4 text-center">
                              <mat-slide-toggle class="mt-2" [disabled]="toggleGenerateBtn()" (change)="checkRange()"  formControlName="valueType">
                                {{item.get('valueType').value ? item.get('name').value + ' -'  : item.get('name').value + ' +'}}
                              </mat-slide-toggle>
                            </div> -->
                            <div class="col-sm-12" *ngIf="item.get('name').value == 'SPH' && sphError">
                              <div class="alert alert-danger">
                                Min {{item.get('min').value}} value greater max value {{item.get('max').value}}
                              </div>
                            </div>
                            <div class="col-sm-12" *ngIf="item.get('name').value == 'ADD' && addError">
                              <div class="alert alert-danger">
                                Min {{item.get('min').value}} value greater max value {{item.get('max').value}}
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                    <!-- <fieldset>
                      <legend>
                        {{ item.get("name").value }}
                        <small class="text-muted">Range</small>
                      </legend>
                    
                      <div class="row p-20">
                          <div class="col-sm-6">
                          <mat-form-field class="w-100">
                            <input
                              type="number"
                              matInput
                              placeholder="Min"
                              step="0.25"
                              formControlName="min"
                              required
                              autocomplete="off"
                            />
                            <mat-hint>min -15</mat-hint>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-6">
                          <mat-form-field class="w-100">
                            <input
                              type="number"
                              matInput
                              placeholder="Max"
                              step="0.25"
                              formControlName="max"
                              required
                              autocomplete="off"
                            />
                            <mat-hint>max 15</mat-hint>
                          </mat-form-field>
                        </div>
                        <div class="col-sm-12">
                            <mat-slide-toggle class="mt-3" (change)="checkRange()"  formControlName="valueType">
                              {{item.get('valueType').value ? item.get('name').value + ' -'  : item.get('name').value + ' +'}}
                            </mat-slide-toggle>
                        </div>
                      </div>
                    </fieldset> -->
                   
                  </div>
  
                </ng-container>
                <div class="col-sm-12 mt-3 mb-3">
                    <div class="float-right">
                    <div *ngIf="SPH_RANGE?.length > 0 && ADD_RANGE?.length > 0;then clearBtn;else generateBtn"></div>
                    <ng-template #clearBtn>
                        <!-- <dx-button text="Clear Range" *ngIf="!isEdit" type="danger" class="float-right" (click)="clearField()"></dx-button> -->
                        <dx-button text="Clear Range" type="danger" class="float-right" (click)="clearField()" *ngIf="!hideClearBtn"></dx-button>
                    </ng-template>
                    <ng-template #generateBtn>
                        <dx-button  id="button" text="Generate Range" *ngIf="toggleGenerateBtn()" type="default"   class="float-right ml-3" (click)="checkRange()" 
                        >
                      </dx-button>
                    </ng-template>
                  </div> 
                </div>
              </div>
              
        <span class="total-count">Total: {{totalQTY}}</span>
        <mat-slide-toggle class="ml-3" color="primary" (change)="toggleStock('previous')" *ngIf="showPreviousStock"> Previous Stock           
        </mat-slide-toggle>
        <div class="clearfix"></div>
        <div class="row mt-2" *ngIf="SPH_RANGE?.length && ADD_RANGE?.length">
            <div class="col">
                <div class="card animated fadeIn">
                    <div class="card-header" *ngIf="isCsv">
                        <div class="float-right">
                            <button type="button" mat-button (click)="csvfileInput.click()"
                                    matTooltip="directly upload csv file" matTooltipPosition="left">
                                <i class="material-icons">cloud_upload</i>
                                <span class="ml-2">upload csv</span>
                                <input #csvfileInput type="file" (change)="readCSV($event)" style="display:none;"/>
                            </button>
                        </div>
                    </div>
                    <div class="card-body pb-0 position-relative">
                        <div class="table-responsive">
                            <table class="table table-striped  text-center table-with-over position-relative">
                              <thead>
                                <tr>
                                  <th class="force-inline"> SPH / ADD </th>
                                  <!-- adding cyl values here -->
                                  <th *ngFor="let add of ADD_RANGE">{{ add }}</th>
                                  <!-- <th>Action</th> -->
                              </tr>
                              </thead>
                                <tbody>
                                  <tr *ngFor="let sph of SPH_RANGE; let sphIndex = index">
                                    <!-- iterating via row and adding sph values on every first child -->
                                    <th class="align-middle">
                                        <b>{{ sph }}</b>
                                    </th>
                                    <!-- using again to balance column cell -->
                                    <td *ngFor="let ad of ADD_RANGE; let addlIndex = index">
                                        <!-- {{ cylIndex }} -->
                                        <div class="stock-container">
                                          <span class="availableStock badge badge-primary" id="availableStock-{{sphIndex + '-' + addlIndex}}"  [ngStyle]="{'visibility': previousStock ? 'visible' : 'hidden'}"></span>
                                            <input tabindex="{{addlIndex + 1}}" maxlength="5" NumberValidate SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" id="input-{{sphIndex + '-' + addlIndex}}"
                                              (input)="getRangeValues($event.target.value, ADD_RANGE[addlIndex], SPH_RANGE[sphIndex]);estimatePrice(rangeStockListPayload)"
                                              class="form-control form-control-sm hvr-glow animated fadeInRight" [readonly]="readonly || acknowledgement" style="min-width:80px" autocomplete="off">
                                              <img *ngIf="stockIcon" src="/assets/img/binoculars.png" id="img-{{sphIndex + '-' + addlIndex}}"
                                              (click)="getAvailableStock(sphIndex + '-' + addlIndex, sph, ad)">
                                         </div>     
   
                                    </td>
                                    <!-- <td><a href="javascript:void(0)" class="cursor-pointer warn"><mat-icon  (click)="removeIndex(sphIndex)">delete_forever</mat-icon></a></td> -->
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <dx-popover position="top" [width]="150" [showTitle]="true" title="Stock In Hand"
              [(visible)]="actionSheetVisible" [target]="actionSheetTarget" [hideOnOutsideClick]="true">
              <!-- <div *dxTemplate="let data = model of 'content'">
                <div class="previousStock" style="font-size: 13px;">Previous Stock: <span>{{previousStock}}</span></div>
                <div class="crrentStock"  style="font-size: 13px;">Crrent Stock: <span>{{crrentStock}}</span></div>
                
              </div>  -->
              <div *dxTemplate="let data = model of 'content'">
                <div [ngClass]="{'text-green': availableStock > 0, 'text-red': availableStock < 0}">
                  {{availableStock}}</div>
              </div>
            </dx-popover>
        <div class="row mt-2" *ngIf="!displayOnly">
            <div class="col animated fadeInUp">
                <!-- <dx-button 
                id="submit"
                [text]="isEdit ? 'Update':  'Save'"
                type="default"  
                [disabled]="loadingIndicator"
                class="float-right ml-3"
                *ngIf="rangeStockListPayload?.length > 0"
                (click)="!requestForm.valid || saveStockRequest(requestForm)"
                [useSubmitBehavior]="true">
            </dx-button> -->
            <dx-button 
                          id="submit"
                          [text]="'Reset'"
                          type="default"  
                          class="float-right ml-3"
                          (click)="resetAll()"
                          [useSubmitBehavior]="false">
                      </dx-button>
            <dx-button text="Cancel" type="normal" class="float-right" appBackButton>
            </dx-button>
            </div>
            <dx-speed-dial-action  *ngIf="rangeStockListPayload?.length > 0"
              icon="add"
              [label]="isEdit ? 'Update':  'Save'"
              [index]="1"
              (onClick)="!requestForm.valid || saveStockRequest(requestForm)">
            </dx-speed-dial-action>
        </div>
      </ng-container>
      </ng-container>
  
    </ng-container>
      </form>
      
    </div>
    <div class="right-nav">
      <div class="card">
        <div class="card-header">
          <div class="float-left">Order Amount</div>
          <div class="float-right">
            <div id="dealInner">
              <i class="fa fa-angle-down fa-3" aria-hidden="true"></i>
            </div>
            <div id="close">
              <i class="fa fa-angle-up fa-3" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Description</th>
                <th class="text-center">Qty</th>
                <th class="text-right">Price</th>
                <th class="text-right">Amount</th>
              </tr>
            </thead>
            <tbody *ngIf="watchRangeStockList">
              <tr *ngFor="let item of priceRange">
                <td>{{item.description}}</td>
                <td class="text-center">
                   {{item.qty}}
                </td>
                <td class="text-center">
                  {{item.price}}
               </td>
               <td class="text-right">
                {{item.amount}}
             </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td >Total</td>
                <td class="text-center">{{itemQty}}</td>
                <td></td>
                <td class="text-right" colspan="2">
                  {{
                      totalAmount
                  }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
  