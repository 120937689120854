import * as momentTimeZone from "moment-timezone";

import { Component, OnInit } from "@angular/core";
import {
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

import { AppSettings } from "./app.settings";
import { Settings } from "./app.settings.model";
import { TranslateService } from "@ngx-translate/core";
import axios from "axios";
import { environment } from "./../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  public settings: Settings;
  loading: boolean;
  constructor(
    public appSettings: AppSettings,
    private router: Router,
    private translate: TranslateService
  ) {
    this.settings = this.appSettings.settings;

    // Add languages
    this.translate.addLangs(["en", "ksa"]);

    // Set the default language
    this.translate.setDefaultLang("en");

    // Use a language
    this.translate.use("en");
  }

  ngOnInit() {
    this.setDefaultConfiguration();
    // this.configureRequestHeader();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        this.loading = true;
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.loading = false;
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        this.loading = false;
        // Present error to user
        console.log(event.error);
      }
    });
  }

  setDefaultConfiguration() {
    axios.defaults.baseURL = environment.baseUrl;
    axios.defaults.headers.post["Content-Type"] = "application/json-patch+json";
  }
  configureRequestHeader() {
    let isRefreshing = false;
    if (localStorage.getItem("userToken")) {
      axios.interceptors.request.use((config) => {
        var currentDate = momentTimeZone(new Date())
          .tz("Asia/Riyadh")
          .format("MM-DD-YYYY HH:mm:ss") as any;
        var tokenExpiaryInSec =
          parseInt(localStorage.getItem("tokenExpireIn")) -
          Math.floor((new Date(currentDate) as any) / 1000);
        if (
          tokenExpiaryInSec > 0 &&
          tokenExpiaryInSec <= 600 &&
          !isRefreshing
        ) {
          isRefreshing = true;
          const payload = {
            userToken: localStorage.getItem("userToken"),
          };
          return axios
            .post("CustomerPortal/RefreshToke", payload)
            .then((response) => {
              if (response.status == 200) {
                localStorage.setItem(
                  "userToken",
                  response.data.payload.userToken
                );
                let expiarytime = null;
                expiarytime = new Date(response.data.payload.expiresIn);
                let expiresIn = Math.floor(expiarytime / 1000);
                localStorage.setItem("tokenExpireIn", expiresIn.toString());
                isRefreshing = false;
                config.headers.common[
                  "Authorization"
                ] = `Bearer ${localStorage.getItem("userToken")}`;
                return config;
              }
            });
        }
        // Do something before request is sent
        config.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
          "userToken"
        )}`;
        return config;
      });
    }
  }
}
