<dx-load-panel
    [hideOnOutsideClick]="false"
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="loadingIndicator">
</dx-load-panel>
<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger" >
    <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
    <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->
        <mat-toolbar color="primary">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                <img src="{{loggedUserImage}}" alt="user-image" width="80" class="rounded mat-elevation-z6">
                <p><small>{{loggedUserName}}</small></p>
            </div>  
        </mat-toolbar>
        <!-- <a mat-menu-item routerLink="profile"> 
            <mat-icon>person</mat-icon>
            <span>Profile</span> 
        </a>
        <a mat-menu-item> 
            <mat-icon>settings</mat-icon>
            <span (click)="updatePasswordContent()">Change Password</span> 
        </a>
        <a mat-menu-item routerLink="/"> 
            <mat-icon>lock</mat-icon>
            <span>Lock screen</span> 
        </a>
        <a mat-menu-item routerLink="help"> 
            <mat-icon>help</mat-icon>
            <span>Help</span> 
        </a> -->
        <div class="divider"></div>
        <a mat-menu-item (click)="logOutUser()">
            <mat-icon>power_settings_new</mat-icon>
            <span>Log out</span> 
        </a>
    <!--</span>-->
</mat-menu>
<dx-popup class="popup" [width]="800" [height]="250" [showTitle]="true" title="Update Password" [dragEnabled]="false" [hideOnOutsideClick]="false" [(visible)]="popupVisible">
    <form [formGroup]="updatePasswordForm" >
        <div class="row">
            <div class="col-sm-4">
                <div class="dx-field">
                    <div class="text-left">Current Password <span>*</span>:</div>
                    <dx-text-box [inputAttr]="{ autocomplete: 'off' }"  mode="password" placeholder="Enter current password" formControlName="currentPassword">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Current Password is required"></dxi-validation-rule>
                            <dxi-validation-rule type="pattern" [pattern]="passwordPattern" message="Invalid Password format, must be at least 6 characters and contain at least one of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (!@#$%^&*)"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="dx-field">
                    <div class="text-left">New Password <span>*</span>:</div>
                    <dx-text-box [inputAttr]="{ autocomplete: 'off' }"  mode="password" placeholder="Enter new password" formControlName="newPassword">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="New Password is required"></dxi-validation-rule>
                            <dxi-validation-rule type="pattern" [pattern]="passwordPattern" message="Invalid Password format, must be at least 6 characters and contain at least one of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (!@#$%^&*)"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="dx-field" style="margin-bottom: 0px !important">
                    <div class="text-left">Confirm Password <span>*</span>:</div>
                    <dx-text-box [inputAttr]="{ autocomplete: 'off' }"  mode="password" placeholder="Enter confirm password" formControlName="confirmPassword">
                        <dx-validator>
                            <dxi-validation-rule type="required" message="Confirm Password is required"></dxi-validation-rule>
                            <dxi-validation-rule type="pattern" [pattern]="passwordPattern" message="Invalid Password format, must be at least 6 characters and contain at least one of the following: upper case (A-Z), lower case (a-z), number (0-9) and special character (!@#$%^&*)"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <p *ngIf="passwordMismatch" class="m-0 error-message">Password doesnot match!</p>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-12">
                <div class="note">
                    <p class="m-0">Note: Password must contain atleast 8 characters and atleast one of the following upper case (A-Z), lower case (a-z), number (0-9) and special character (!@#$%^&*)</p>
                </div>
            </div>
        </div>
        <div text="text-right">
            <dx-button text="Update" type="default"
                (click)="!updatePasswordForm.valid || updatePassword(updatePasswordForm)" 
                class="float-right ml-3"
                [useSubmitBehavior]="true"
            >
            </dx-button>
        </div>
    </form>
</dx-popup>
<swal #saveSwal></swal>