import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ErrorComponent } from "./template/pages/errors/error/error.component";
import { NotFoundComponent } from "./template/pages/errors/not-found/not-found.component";
import { PagesComponent } from "./template/pages/pages.component";

export const routes: Routes = [
  {
    path: "portal",
    component: PagesComponent,
    children: [

      { path: 'sales', 
      loadChildren: () => import('./components/sales/sales.module').then(m => m.SalesModule)
      , data: { breadcrumb: 'Sales' } },
      {
        path: "customers",
        loadChildren: () => import('./components/customer-contect-login/customer-contect-login.module').then(m => m.CustomerContectModule),
        data: { breadcrumb: "Customers" },
      },
      {
        path: "reports",
        loadChildren: () => import('./components/reports/reports.module').then(m => m.ReportsModule),
        data: { breadcrumb: "Reports" },
      },
      // { path: 'soa-request', loadChildren: './components/soa-request/soa-request.module#SoaRequestModule', data: { breadcrumb: 'SOA Request' } },
    ],
  },
  {
    path: 'authentication',
    loadChildren: () => import('././components/authentication/authentication.module').then(m => m.AuthenticationModule)
 },
  { path: "", redirectTo: "authentication/login", pathMatch: "full" },
  { path: "error", component: ErrorComponent, data: { breadcrumb: "Error" } },
  {
    path: "no-access",
    component: NotFoundComponent,
    data: { breadcrumb: "No Access" },
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            // relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
// export const routing: ModuleWithProviders  = RouterModule.forRoot(routes, {
//     // preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
//     //  useHash: true
// });
