import { Directive, HostListener } from "@angular/core";
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[MaxLengthValidate]",
})
// tslint:disable-next-line:directive-class-suffix
export class MaxLengthValidate {
  @HostListener("keypress", ["$event"])
  onkeypress(event: any) {
    if(event && event.target.value.toString().length > 8) {
      event.stopPropagation();
      return false;
    }
  }
}
