<div class="form-cyl-container mt-3">
  <div class="">
    <form [formGroup]="requestForm">
      <ng-container class="row mt-3" formArrayName="sections">
        <ng-container *ngFor="let section of sectionsArray.controls; let i = index">
          <ng-container [formGroupName]="i">
            <div class="row">
              <ng-container formArrayName="childern">
                <div class="col-sm-6" [formGroupName]="j" *ngFor="let item of getChildern(section); let j = index">

                  <div class="card">
                    <div class="card-header">
                      {{ item.get("name").value }} 
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="dx-field" *ngIf="item.get('name').value == 'SPH'">
                            <div>MIN<span>*</span>:</div>
                            <dx-select-box
                              [inputAttr]="{ autocomplete: 'off' }"
                              class="enter-keypress-focus-input"
                              formControlName="min"
                              [items]="sphRangeList"
                              [readOnly]="sphRangeList?.length == 0"
                              placeholder="Select MIN *"
                              displayExpr="sphValue"
                              valueExpr="sphValue"
                              [showClearButton]="true"
                              [showDataBeforeSearch]="false"
                              deferRendering="false"
                              [searchEnabled]="true"
                              (onValueChanged)="checkRange()"
                              >
                              <dx-validator>
                                <dxi-validation-rule type="required" message="Min required">
                                </dxi-validation-rule>
                              </dx-validator>
                            </dx-select-box>
                          </div>
                          <div class="dx-field" *ngIf="item.get('name').value == 'CYL'">
                            <div>MIN<span>*</span>:</div>
                            <dx-select-box
                              [inputAttr]="{ autocomplete: 'off' }"
                              class="enter-keypress-focus-input"
                              formControlName="min"
                              [items]="sphRangeList"
                              [readOnly]="sphRangeList?.length == 0"
                              placeholder="Select MIN *"
                              displayExpr="sphValue"
                              valueExpr="sphValue"
                              [showClearButton]="true"
                              [showDataBeforeSearch]="false"
                              deferRendering="false"
                              [searchEnabled]="true"
                              (onValueChanged)="checkRange()"
                              >
                              <dx-validator>
                                <dxi-validation-rule type="required" message="Min required">
                                </dxi-validation-rule>
                              </dx-validator>
                            </dx-select-box>
                          </div>
                          <!-- <div class="dx-field">
                            <dx-number-box [readOnly]="toggleGenerateBtn()" [inputAttr]="{ autocomplete: 'off' }"  [step]="0.25"  placeholder="Min" formControlName="min">
                              <dx-validator>
                                <dxi-validation-rule type="required" message="Min required">
                                </dxi-validation-rule>
                                <dxi-validation-rule type="range" message="Negative sign not allowed" [min]="0" [max]="99999"></dxi-validation-rule> 
                              </dx-validator>
                            </dx-number-box>
                          </div> -->
                        </div>
                        <div class="col-sm-4">
                          <div class="dx-field" *ngIf="item.get('name').value == 'SPH'">
                            <div>MAX<span>*</span>:</div>
                            <dx-select-box
                              [inputAttr]="{ autocomplete: 'off' }"
                              class="enter-keypress-focus-input"
                              formControlName="max"
                              [items]="sphRangeList"
                              [readOnly]="sphRangeList?.length == 0"
                              placeholder="Select MAX *"
                              displayExpr="sphValue"
                              valueExpr="sphValue"
                              [showClearButton]="true"
                              [showDataBeforeSearch]="false"
                              deferRendering="false"
                              [searchEnabled]="true"
                              (onValueChanged)="checkRange()"
                              >
                              <dx-validator>
                                <dxi-validation-rule type="required" message="Max required">
                                </dxi-validation-rule>
                              </dx-validator>
                            </dx-select-box>
                          </div>
                          <div class="dx-field" *ngIf="item.get('name').value == 'CYL'">
                            <div>MAX<span>*</span>:</div>
                            <dx-select-box
                            [inputAttr]="{ autocomplete: 'off' }"
                            class="enter-keypress-focus-input"
                            formControlName="max"
                            [items]="cylRangeList"
                            [readOnly]="cylRangeList?.length == 0"
                            placeholder="Select MAX *"
                            displayExpr="cylValue"
                            valueExpr="cylValue"
                            deferRendering="false"
                            [showClearButton]="true"
                            [showDataBeforeSearch]="false"
                            (onValueChanged)="checkRange()"
                            [searchEnabled]="true">
                              <dx-validator>
                                <dxi-validation-rule type="required" message="Max required">
                                </dxi-validation-rule>
                              </dx-validator>
                            </dx-select-box>
                          </div>
                          <!-- <div class="dx-field">
                            <dx-number-box [readOnly]="toggleGenerateBtn()"
                             [inputAttr]="{ autocomplete: 'off' }"  [step]="0.25" placeholder="Max"  formControlName="max">
                              <dx-validator>
                                <dxi-validation-rule type="required" message="Max required">
                                </dxi-validation-rule>
                                <dxi-validation-rule type="range" message="Negative sign not allowed" [min]="0" [max]="99999"></dxi-validation-rule> 
                              </dx-validator>
                            </dx-number-box>
                          </div> -->
                        </div>
                        <div class="col-sm-4 text-center justify-content-center">
                          <mat-slide-toggle class="mt-3" [disabled]="toggleGenerateBtn()" (change)="checkRange()" formControlName="valueType">
                            {{item.get('valueType').value ? item.get('name').value + ' -'  : item.get('name').value + ' +'}}
                          </mat-slide-toggle>
                        </div>
                        <div class="col-sm-12" *ngIf="item.get('name').value == 'SPH' && sphError">
                          <div class="alert alert-danger">
                            Min {{item.get('min').value}} value greater max value {{item.get('max').value}}
                          </div>
                        </div>
                        <div class="col-sm-12" *ngIf="item.get('name').value == 'CYL' && cylError">
                          <div class=" alert alert-danger">
                            Min {{item.get('min').value}} value greater max value {{item.get('max').value}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </ng-container>
              <div class="col-sm-12 mt-3 mb-3">
                <div class="float-right">
                  <div *ngIf="SPH_RANGE?.length && CYL_RANGE?.length;then clearBtn;else generateBtn"></div>
                  <ng-template #clearBtn>
                    <!-- <dx-button text="Clear Range" *ngIf="!isEdit" type="danger" class="float-right"
                      (click)="clearField()"></dx-button> -->
                      <dx-button text="Clear Range" type="danger" class="float-right"
                      (click)="clearField()" *ngIf="!hideClearBtn"></dx-button>
                  </ng-template>
                  <ng-template #generateBtn>
                    <dx-button id="button" text="Generate Range" *ngIf="toggleGenerateBtn()" type="default"
                      class="float-right ml-3" (click)="checkRange()">
                    </dx-button>
                  </ng-template>
                </div>
              </div>
            </div>
            
            <span class="total-count" *ngIf="totalToggle">Total: {{totalQTY}}</span>
            <span>
              <mat-slide-toggle class="ml-3" color="primary" (change)="toggleStock('previous')" *ngIf="showPreviousStock"> Previous Stock           
              </mat-slide-toggle>
            </span>
            <span>
              <mat-slide-toggle class="ml-3" (change)="toggleStock('current')" *ngIf="showCurrentStock"> Current Stock           
              </mat-slide-toggle>
            </span>

            <div class="clearfix"></div>
            <div class="row mt-2" *ngIf="SPH_RANGE?.length && CYL_RANGE?.length">
              <div class="col">
                <div class="card animated fadeIn">
                  <div class="card-header" *ngIf="isCsv">
                    <div class="float-right">
                      <button type="button" mat-button (click)="csvfileInput.click()"
                        matTooltip="directly upload csv file" matTooltipPosition="left">
                        <i class="material-icons">cloud_upload</i>
                        <span class="ml-2">upload csv</span>
                        <input #csvfileInput type="file" (change)="readCSV($event)" style="display:none;" />
                      </button>
                    </div>
                  </div>
                  <div class="card-body pb-0 position-relative">
                    <div class="table-responsive">
                      <table class="table table-striped  text-center table-with-over reset-tabindex">
                        <thead>
                          <tr>
                            <th class="force-inline"> SPH / CYL </th>
                            <!-- adding cyl values here -->
                            <th *ngFor="let cyl of CYL_RANGE">{{ cyl }}</th>
                            <!-- <th>Action</th> -->
                          </tr>
                        </thead>
                        <tbody>

                          <tr *ngFor="let sph of SPH_RANGE; let sphIndex = index">
                            <th class="align-middle">
                              <b>{{ sph }}</b>
                            </th>
                            <td *ngFor="let cyl of CYL_RANGE; let cylIndex = index">
                           
                              <div class="stock-container">
                                <span class="availableStock badge badge-primary" id="availableStock-{{sphIndex + '-' + cylIndex}}"  [ngStyle]="{'visibility': previousStock ? 'visible' : 'hidden'}"></span>
                                <input tabindex="{{cylIndex + 1}}" maxlength="5" NumberValidate SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" id="input-{{sphIndex + '-' + cylIndex}}"
                                  (input)="getRangeValues($event.target.value, CYL_RANGE[cylIndex], SPH_RANGE[sphIndex]);estimatePrice(rangeStockListPayload)"
                                  autocomplete="off"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight" style="min-width:80px" [readonly]="readonly || acknowledgement">
                                  <span class="currentStock badge badge-info" id="currentStock-{{sphIndex + '-' + cylIndex}}" [ngStyle]="{'visibility': currentStock ? 'visible' : 'hidden'}"></span>
                                <img *ngIf="stockIcon" src="/assets/img/binoculars.png" id="img-{{sphIndex + '-' + cylIndex}}"
                                  (click)="getAvailableStock(sphIndex + '-' + cylIndex, sph, cyl)">
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <dx-popover position="top" [width]="150" [showTitle]="true" title="Stock In Hand"
              [(visible)]="actionSheetVisible" [target]="actionSheetTarget" [hideOnOutsideClick]="true">
              <div *dxTemplate="let data = model of 'content'">
                <div [ngClass]="{'text-green': availableStock > 0, 'text-red': availableStock < 0}">
                  {{availableStock}}</div>
              </div>
            </dx-popover>
            <div class="row mt-2" *ngIf="!displayOnly">
              <div class="col  animated fadeInUp">
                <!-- <dx-button id="button" [disabled]="loadingIndicator" [text]="isEdit ? 'Update':  'Save'" type="default" class="float-right ml-3"
                  *ngIf="rangeStockListPayload?.length > 0" [useSubmitBehavior]="true" 
                   (click)="!requestForm.valid || saveStockRequest(requestForm)" [elementAttr]="">
                </dx-button> -->
                <dx-button 
                          id="submit"
                          [text]="'Reset'"
                          type="default"  
                          class="float-right ml-3"
                          (click)="resetAll()"
                          [useSubmitBehavior]="false">
                      </dx-button>
                <dx-button text="Cancel" type="normal" class="float-right" appBackButton>
                </dx-button>
                
              </div>
              
                <dx-speed-dial-action  *ngIf="!holdSalesOrder && rangeStockListPayload?.length > 0"
                  icon="add"
                  [label]="isEdit ? 'Update':  'Save'"
                  [index]="1"
                  (onClick)="!requestForm.valid || saveStockRequest(requestForm)">
                </dx-speed-dial-action>
            </div>
          </ng-container>
        </ng-container>

      </ng-container>
    </form>
  </div>
  <div class="right-nav">
    <div class="card">
      <div class="card-header">
        <div class="float-left">Order Amount</div>
        <div class="float-right">
          <div id="dealInner">
            <i class="fa fa-angle-down fa-3" aria-hidden="true"></i>
          </div>
          <div id="close">
            <i class="fa fa-angle-up fa-3" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="card-body">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Description</th>
              <th class="text-center">Qty</th>
              <th class="text-right">Price</th>
              <th class="text-right">Amount</th>
            </tr>
          </thead>
          <tbody *ngIf="watchRangeStockList">
            <tr *ngFor="let item of priceRange">
              <td>{{item.description}}</td>
              <td class="text-center">
                 {{item.qty}}
              </td>
              <td class="text-center">
                {{item.price}}
             </td>
             <td class="text-right">
              {{item.amount}}
           </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td >Total</td>
              <td class="text-center">{{itemQty}}</td>
              <td></td>
              <td class="text-right" colspan="2">
                {{
                    totalAmount
                }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</div>
