import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { DialogAddRows } from '../dilog/add-rows.component';
import { MatDialog } from '@angular/material/dialog';
import { Papa } from 'ngx-papaparse';
import { SharedService } from './../../shared.service';

declare var $: any;




@Component({
    selector: 'app-cyl-form',
    templateUrl: './cyl-form.component.html',
    styleUrls: ['./cyl-form.component.scss']
})
export class CylFormComponent implements OnInit, OnChanges, OnDestroy {
    requestForm: FormGroup;
    @Input() dataSource: any;
    @Output() optSelected: EventEmitter<any> = new EventEmitter();
    @Input() itemCatalogId: any;
    @Input() itemCatalogGroupId: any;
    @Input() focalId: any;
    @Input() itemTypeMasterId: any;
    @Input() companyBranchId: any;
    @Input() acknowledgement: any;
    loadingIndicator: boolean;
    showGenerateBtn: boolean;
    isEdit: boolean;
    SPH_RANGE = [];
    CYL_RANGE = [];
    barCodeSph: any;
    barCodeCyl: any;
    rangeStockListPayload = [];
    toggleTypesOfValue;
    isCsv: boolean;
    actionSheetVisible: boolean;
    actionSheetTarget: any;
    sphError: boolean;
    cylError: boolean;
    stockIcon: boolean;
    inputType: boolean;
    totalQTY: number;
    totalToggle: boolean;
    loadingStockAvailable: boolean;
    availableStock: number;
    previousStock: boolean;
    currentStock: boolean;
    showPreviousStock: boolean;
    showCurrentStock: boolean;
    readonly: boolean;
    displayOnly: boolean;
    rangeLoading: boolean;
    loadingAllAvailableStock: boolean;
    hideClearBtn: boolean;
    constructor(
        private sharedService: SharedService,
        public dialog: MatDialog,
        private papa: Papa
    ) {

    }

    ngOnInit() {
        this.totalQTY = 0;
        this.availableStock = 0;
        // this.showGenerateBtn = false;
        this.barCodeCyl = [];
        this.barCodeSph = [];
       // this.displayOnly = false;
    }

    // get readOnlyforGen () {
    //     if (this.showGenerateBtn) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }

    readCSV(event) {
        let files: FileList = event.target.files;
        if (files && files.length > 0) {
            let file: File = files.item(0);
            let reader: FileReader = new FileReader();
            reader.readAsText(file);
            reader.onload = (e) => {
                let csvData: string = reader.result as string;
                this.papa.parse(csvData, {
                    complete: (result) => {
                        result.data.map((parentArray, parentArrayIndex) => {
                            parentArray.map((value, childArrayIndex) => {
                                $('table tr td #input-' + parentArrayIndex + '-' + childArrayIndex).val(value);
                                this.getRangeValues(value, this.CYL_RANGE[childArrayIndex], this.SPH_RANGE[parentArrayIndex])
                            })
                        });
                    }
                });
            }
        }
    }

    getInitForm() {
        this.requestForm = new FormGroup({
            sections: new FormArray([])
        });
    }

    /**
     * @name ngOnChanges
     * @desc Fires when change in input properties are detected
     * @return void
     */
    ngOnChanges(changes: SimpleChanges) {
        this.getInitForm();
        if (Object.keys(changes.dataSource.currentValue).length > 0) {
            this.rangeStockListPayload = [];
            this.previousStock = false;
            this.inputType = changes.dataSource.currentValue.inputType;
            this.stockIcon = changes.dataSource.currentValue.stockIcon;
            this.totalToggle = changes.dataSource.currentValue.totalToggle;
            this.showCurrentStock = changes.dataSource.currentValue.showCurrentStock;
            this.showPreviousStock = changes.dataSource.currentValue.showPreviousStock;
            this.hideClearBtn = changes.dataSource.currentValue.hideClearBtn;
            this.showGenerateBtn = changes.dataSource.currentValue.generateBtn;
           // this.previousStock = changes.dataSource.currentValue.previousStock;
            this.readonly = changes.dataSource.currentValue.readonly;
            this.displayOnly = changes.dataSource.currentValue.displayOnly;
            
            this.addSection(changes.dataSource.currentValue);
            if (!this.showGenerateBtn) {
                this.checkRange();
            }
            if (changes.dataSource.currentValue.isEdit) {
                if (changes.dataSource.currentValue.rangeStockListPayload) {
                     this.setInventoryFormData(changes.dataSource.currentValue.rangeStockListPayload);
                }
                this.isEdit = true;
            }
            if (changes.dataSource.currentValue.rangeOption) {
                const payload = {
                    itemCatalogGroupId: this.itemCatalogGroupId,
                    itemTypeMasterId: this.itemTypeMasterId,
                    focalId: this.focalId,
                    sphmin: (changes.dataSource.currentValue.barCodeData.sphmin || changes.dataSource.currentValue.barCodeData.sphmin == 0) ? changes.dataSource.currentValue.barCodeData.sphmin : 0,
                    sphmax: (changes.dataSource.currentValue.barCodeData.sphmax || changes.dataSource.currentValue.barCodeData.sphmax == 0)  ? changes.dataSource.currentValue.barCodeData.sphmax : 0,
                    cylmin: (changes.dataSource.currentValue.barCodeData.cylmin || changes.dataSource.currentValue.barCodeData.cylmin == 0)  ? changes.dataSource.currentValue.barCodeData.cylmin : 0,
                    cylmax: (changes.dataSource.currentValue.barCodeData.cylmax || changes.dataSource.currentValue.barCodeData.cylmax == 0) ? changes.dataSource.currentValue.barCodeData.cylmax : 0,
                    addmin: (changes.dataSource.currentValue.barCodeData.addmin || changes.dataSource.currentValue.barCodeData.addmin == 0)  ? changes.dataSource.currentValue.barCodeData.addmin : 0,
                    addmax: (changes.dataSource.currentValue.barCodeData.addmax || changes.dataSource.currentValue.barCodeData.addmax == 0) ? changes.dataSource.currentValue.barCodeData.addmax : 0,
                    cxmin:  (changes.dataSource.currentValue.barCodeData.cxmin || changes.dataSource.currentValue.barCodeData.cxmin == 0) ? changes.dataSource.currentValue.barCodeData.cxmin : 0,
                    cxmax:  (changes.dataSource.currentValue.barCodeData.cxmax || changes.dataSource.currentValue.barCodeData.cxmax == 0) ? changes.dataSource.currentValue.barCodeData.cxmax : 0,
                };
                // this.barCodeSph = this.sharedService.generateRange((changes.dataSource.currentValue.sphmin as string).replace('-',''),(changes.dataSource.currentValue.sphmax as string).replace('-',''), 0.25);
                // this.barCodeCyl = this.sharedService.generateRange((changes.dataSource.currentValue.cylmin as string).replace('-',''),(changes.dataSource.currentValue.cylmax as string).replace('-',''), 0.25);
                this.validateBarcodeRequestRange(payload);
            }
        }
    }

    /**
     * @name addSection
     * @param data
     * @returns void
     * @description create section array
     */
    addSection(data?: any): void {
        if (data) {
            if (data.active) {
                this.showGenerateBtn = data.generateBtn;
                this.isCsv = data.csv;
                this.sectionsArray.push(this.createSection(data));
            }
        }
    }

    /**
     * @name createSection
     * @param data
     * @return FormGroup
     * @description section controls
     */
    createSection(data?: any): FormGroup {
        return new FormGroup({
            condition: new FormControl(data ? data.condition : '', []),
            active: new FormControl(data ? data.active : false, []),
            childern: new FormArray(this.addChildern(data.childern)),
        });
    }


    /**
     * @name addChildern
     * @param data
     * @returns childern array
     */
    addChildern(data?: any) {
        const formArray = [];
        data.forEach(child => {
            formArray.push(this.createChild(child));
        });
        return formArray;
    }

    /**
     * @name createChild
     * @param child
     * @return  childer controls
     * @deprecated child formcontrols
     */
    createChild(child?): FormGroup {
        return new FormGroup({
            name: new FormControl(child ? child.name : '', []),
            min: new FormControl(child ? child.min : '', [Validators.required]),
            max: new FormControl(child ? child.max : '', [Validators.required]),
            // min: new FormControl(child ? (child.valueType ? child.min : child.min.toString().replace('-','')) : '', [Validators.required]),
            // max: new FormControl(child ? (child.valueType ? child.max : child.max.toString().replace('-','')) : '', [Validators.required]),
            valueType: new FormControl(child.valueType, [])
            // valueType: new FormControl(child.name === 'CYL' ? true : false, [])
        });
    }


    /**
     * @name getAvailableStock
     * @param {*} e
     * @param {*} sph
     * @param {*} cyl
     * @memberof CylFormComponent
     */
    getAvailableStock(e, sph: any, cyl: any) {
        this.availableStock = 0;
        this.loadingStockAvailable = true;
        let payload = {
            itemCatalogGroupId: this.itemCatalogGroupId,
            sph: sph,
            cylOrAdd: cyl
        };
        this.sharedService.getAvailableStock(payload)
            .then((response: any) => {
                if (response.data.completed) {
                    this.loadingStockAvailable = false;
                    this.availableStock = response.data.payload;
                    this.actionSheetTarget = '#img-' + e;
                    this.actionSheetVisible = true;
                } else {
                    this.loadingStockAvailable = false;
                    this.availableStock = 0;
                    console.error(response);
                }
            })
            .catch(error => {
                this.availableStock = 0;
                this.loadingStockAvailable = false;
                console.log(error);

            });

    }

   

    /**
   * @name validateBarcodeRequestRange
   * @param {number} itemCatalogGroupId
   * @param {number} itemTypeMasterId
   * @param {number} focalId
   * @param {number} sphmin
   * @param {number} sphmax
   * @param {number} cylmin
   * @param {number} cylmax
   * @param {*} payload
   * @returns
   * @memberof SharedService
   */
    validateBarcodeRequestRange(payload) {
       this.rangeLoading = true;
        this.sharedService.validateBarcodeRequestRange(payload)
            .then((response: any) => {
                if (response.data.completed && response.data.payload.length > 0) {
                    this.rangeLoading = false;
                    this.setBarCodeRange(response.data.payload);
                } else {
                    this.rangeLoading = false;
                    console.error(response);
                }
            })
            .catch(error => {
                this.rangeLoading = false;
                console.log(error);

            });

    }
    /**
     * @name sectionsArray
     * @returns section array controls
     */
    get sectionsArray() {
        return this.requestForm.get('sections') as FormArray;
    }

    /**
     * @name getChildern
     * @param form section item index
     * @returns childern array controls
     */
    getChildern(form) {
        return form.controls.childern.controls;
    }

  


    /**
     * @name clearField
     * @return void
     */
    clearField(): void {
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').setValue('');
        ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue('');
        // const child = (this.requestForm.get('sections') as FormArray).at(0).get('childern') as FormArray;
        // while (child.length !== 0) {
        //     child.removeAt(0);
        // }
        this.rangeStockListPayload = [];
        this.SPH_RANGE = [];
        this.CYL_RANGE = [];
    }
    get availableStockInHand() {
        return Math.floor(Math.random() * 500);
    }

    /**
     * @name addRows
     * @return number of rows
     * @description modal popup
     */
    addRows() {
        const dialogRef = this.dialog.open(DialogAddRows, {
            width: '250px',
            data: { label: 'CYL' }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result.rows) {
                for (let i = 0; i < parseInt(result.rows); i++) {
                    // this.createCxTypeDetail();
                    this.updatedMaxVal('increment');
                }
            }
        });
    }

    /**
     * @name incrementMaxVal
     * @returns updated max value
     */
    updatedMaxVal(label: string) {
        const val = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        if (label === 'increment') {
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) + 1);
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue(parseInt(val) + 1);
        }
        if (label === 'decrement') {
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').setValue(parseInt(val) - 1);
            ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').setValue(parseInt(val) - 1);
        }
    }

    /**
     * @name removeIndex
     * @param index
     * @return void
     * @description removing row index
     */
    removeIndex(index) {
        // this.SPH_RANGE.splice(index, 1);
        // this.CYL_RANGE.splice(index, 1);
        // const item = (this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray;
        // item.removeAt(index);
        this.updatedMaxVal('decrement');
        // ((this.requestForm.get('sections') as FormArray).at(0).get('cxTypeDetail') as FormArray).removeAt(index);
    }

    getRangeValues(val, cyl, sph) {

        if (!val) {
            // if value empty then remove it from collection
            for (let i = 0; i < this.rangeStockListPayload.length; i++) {
                if (
                    this.rangeStockListPayload[i].cyl === cyl &&
                    this.rangeStockListPayload[i].sph === sph
                ) {
                    this.rangeStockListPayload.splice(i, 1);
                    break;
                }
            }
            this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
            return;
        }
        // check whether the value already exist then modify it
        if (this.rangeStockListPayload.some(o => o.cyl === cyl && o.sph === sph)) {
            for (let i = 0; i < this.rangeStockListPayload.length; i++) {
                if (
                    this.rangeStockListPayload[i].cyl === cyl &&
                    this.rangeStockListPayload[i].sph === sph
                ) {
                    this.rangeStockListPayload[i]['vendorItemCode'] = val;
                    break;
                }
            }
            this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        } else {
            // else not found so push it
            const rangePairObj = {
                cx: null,
                cxtypeId: null,
                vendorItemCode: val,
                cc: null,
                add: null,
                axis: null,
                cyl: cyl,
                ct: null,
                et: null,
                tc: null,
                wt: null,
                sph: sph,
                // qty: eval(val)
            };
            //this.totalQTY += val;
            this.rangeStockListPayload.push(rangePairObj);
            this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
        }

    }

    // overLapRange(sph_min: any, sph_max: any, cyl_min: any, cyl_max: any) {
    //     let tempSPH_RANGE = [];
    //     let tempCYL_RANGE = [];
    //     if (sph_min && sph_max) {
    //         if (sph_max < sph_min) {
    //             return this.sphError = true;
    //         } else {
    //             this.sphError = false;
    //             if ((sph_min && sph_max || sph_min === 0 && sph_max || sph_min === 0 && sph_max == 0 || sph_min && sph_max === 0)) {
    //                 tempSPH_RANGE = this.sharedService.generateRange(
    //                     sph_min,
    //                     sph_max,
    //                     0.25
    //                   );
    //             }
    //         }
    //     }
    //     if (cyl_max < cyl_min) {
    //         return this.cylError = true;
    //     } else {
    //         this.cylError = false;
    //          if ((cyl_min && cyl_max || cyl_min === 0 && cyl_max || cyl_min === 0 && cyl_max === 0 || cyl_min && cyl_max === 0)) {
    //             tempCYL_RANGE = this.sharedService.generateRange(
    //                     cyl_min,
    //                     cyl_max,
    //                     0.25
    //                   );
    //             if (this.check_number(cyl_min)) {
    //                 tempCYL_RANGE.reverse();
    //             }
    //         } 
    //     }
    //     console.log('tempSPH_RANGE', tempSPH_RANGE.length)
    //     console.log('tempCYL_RANGE', tempCYL_RANGE.length)
    //     console.log('this.SPH_RANGE', this.SPH_RANGE.length)
    //     console.log('this.CYL_RANGE', this.CYL_RANGE.length)
    //     this.SPH_RANGE.forEach((sph, sphIndex) => {
    //         this.CYL_RANGE.forEach((cyl, index) => {
    //             if (tempSPH_RANGE.includes(sph) && tempCYL_RANGE.includes(cyl)) {
    //                 $('table tr td #delete-' + sphIndex + '-' + index).addClass('fa fa-trash d-none');
    //             } else {
    //                 $('table tr td #delete-' + sphIndex + '-' + index).removeClass('d-none');
    //             }
    //             // dataSource.forEach(data => {
    //             //     if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
    //             //         setTimeout(() => {
    //             //              $('table tr td #currentStock-' + sphIndex + '-' + index).text(data.vendorItemCode);
    //             //         }, 10);
    //             //     }
    //             // });
    //         });
    //     });
        
    // }
    checkRange() {
        this.SPH_RANGE = [];
        this.CYL_RANGE = [];
        const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
        const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        const sph_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('valueType').value;
        const cyl_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
        const cyl_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
        const cyl_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value;
        if (parseFloat(sph_max) < parseFloat(sph_min)) {
            return this.sphError = true;
        } else {
            this.sphError = false;
            if ((sph_min && sph_max || sph_min === 0 && sph_max || sph_min === 0 && sph_max == 0 || sph_min && sph_max === 0)) {
                // if ((sph_min !== "" || sph_min == 0 ) && (sph_max !== "" || sph_max == 0) && sph_min !== null && sph_max !== null) {
                    this.SPH_RANGE = this.sharedService.generateRange(
                        parseFloat(sph_min),
                        parseFloat(sph_max),
                        0.25
                      );
                // this.SPH_RANGE = this.sharedService.generateRange(sph_valueType ? -sph_max : sph_min, sph_valueType ? -sph_min : sph_max, 0.25);
                if (this.check_number(parseFloat(sph_min))) {
                    this.SPH_RANGE.reverse();
                }
            } else {
                this.SPH_RANGE = null;
            }
        }
        if (parseFloat(cyl_max) < parseFloat(cyl_min)) {
            return this.cylError = true;
        } else {
            this.cylError = false;
             if ((cyl_min && cyl_max || cyl_min === 0 && cyl_max || cyl_min === 0 && cyl_max === 0 || cyl_min && cyl_max === 0)) {
                // if ((cyl_min !== "" || cyl_min == 0) && (cyl_max !== "" || cyl_max == 0) && cyl_min !== null && cyl_max !== null) {
                    this.CYL_RANGE = this.sharedService.generateRange(
                        parseFloat(cyl_min),
                        parseFloat(cyl_max),
                        0.25
                      );
                // (cyl_max < cyl_min) ? this.isCylInvalid = true : this.isCylInvalid = false;
                // this.CYL_RANGE = this.sharedService.generateRange(cyl_valueType ? -cyl_max : cyl_min, cyl_valueType ? -cyl_min : cyl_max, 0.25);
                if (this.check_number(parseFloat(cyl_min))) {
                    this.CYL_RANGE.reverse();
                }
            } else {
                this.CYL_RANGE = null;
            }
        }
    }

      
  /**
   * @name check_number
   * @param num_value
   * @returns bool
   */
  check_number(num_value) {
    if (num_value >= 0) {
      return false;
    } else {
      return true;
    }
  }
    toggleStock(label: string) {
       switch (label) {
           case 'previous':
               this.previousStock = !this.previousStock;
               this.getAllAvailableStock(this.companyBranchId, this.itemCatalogGroupId, ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value);
               break;
            case 'current':
               this.currentStock = !this.currentStock;
               this.setCurrentStock(this.dataSource.rangeStockListPayload);
             break;
       }
    }
     /**
   * @name getAllAvailableStock
   * @param {number} companyBranchId
   * @param {number} itemCatalogGroupId
   * @param {number} isPositiveRange
   * @returns
   * @memberof SharedService
   */
  getAllAvailableStock(companyBranchId: number, itemCatalogGroupId: number, isPositiveRange: boolean) {
    this.loadingAllAvailableStock = true;
    this.sharedService.getAllAvailableStock(companyBranchId, itemCatalogGroupId, isPositiveRange)
        .then((response: any) => {
            if (response.data.completed) {
            this.checkRange();
            this.loadingAllAvailableStock = false;
            this.setAvailableStock(response.data.payload.availableStock);
            } else {
                this.loadingAllAvailableStock = false;
                console.error(response);
            }
        })
        .catch(error => {
            this.loadingAllAvailableStock = false;
            console.log(error);

        });

 }
  /**
     * @name setCurrentStock
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setAvailableStock(dataSource) {
        // if (this.SPH_RANGE && this.SPH_RANGE.length > 0 && this.CYL_RANGE && this.CYL_RANGE.length > 0) {
            this.SPH_RANGE.forEach((sph, sphIndex) => {
                this.CYL_RANGE.forEach((cyl, index) => {
                    dataSource.forEach(data => {
                        if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
                            setTimeout(() => {
                                 $('table tr td #availableStock-' + sphIndex + '-' + index).text(data.availableStock);
                            }, 10);
                        }
                    });
                });
            });
        // } else {
        //     console.error('Line: 634-SPH AND CYL LENGHT 0');
        // }
       
    }
 /**
     * @name setCurrentStock
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setCurrentStock(dataSource) {
        if (this.SPH_RANGE && this.SPH_RANGE.length > 0 && this.CYL_RANGE && this.CYL_RANGE.length > 0) {
        this.SPH_RANGE.forEach((sph, sphIndex) => {
            this.CYL_RANGE.forEach((cyl, index) => {
                dataSource.forEach(data => {
                    if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
                        setTimeout(() => {
                             $('table tr td #currentStock-' + sphIndex + '-' + index).text(data.vendorItemCode);
                        }, 10);
                    }
                });
            });
        });
    } else {
        console.error('Line: 617-SPH AND CYL LENGHT 0')
    }
    }
    /**
     * @name setInventoryFormData
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setInventoryFormData(dataSource) {
        this.SPH_RANGE.forEach((sph, sphIndex) => {
            this.CYL_RANGE.forEach((cyl, index) => {
                dataSource.forEach(data => {
                    if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
                        this.getRangeValues(data.vendorItemCode, cyl, sph);
                        setTimeout(() => {
                            $('table tr td #input-' + sphIndex + '-' + index).val(data.vendorItemCode);
                            if (this.isEdit) {
                                this.totalQTY = this.sharedService.getTotal(this.rangeStockListPayload, 'vendorItemCode');
                            }
                        }, 10);
                    }
                });
            });
        });
    }
    /**
     * @name setInventoryFormData
     * @requires params SPH_RANGE or CYL_RANGE
     * @returns value
     */
    setBarCodeRange(dataSource) {
        // if (data.sph && data.cyl) {
            this.SPH_RANGE.forEach((sphValue, sphIndex) => {
                this.CYL_RANGE.forEach((cylValue, index) => {
                    dataSource.forEach(data => {
                    let s = sphValue.toString().replace('-','');
                    let c = cylValue.toString().replace('-', '');
                    let sp = data.sph.toFixed(2).replace('-','');
                    let cy = data.cyl.toFixed(2).replace('-','');
                    $('table tr td #input-' + sphIndex + '-' + index).attr('disabled', 'disabled');
                        if (sp == s && cy == c) {
                            // console.log('s', sp + ' ' + s)
                            // console.log('c', cy + ' ' + c)
                            setTimeout(() => {
                                     $('table tr td #input-' + sphIndex + '-' + index).removeAttr('disabled');
                            }, 10);
                        } 
                    });
                });
        // }
        
        });
    }
    /***
     * @name toggleGenerateBtn
     * @returns boolean;
     */
    toggleGenerateBtn() {
        if (((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).length > 0) {
            const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
            const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
            const cyl_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
            const cyl_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
                if ((sph_min !== "" && sph_max !== "" && cyl_min !== "" && cyl_max !== "") && ((sph_min || sph_min == 0) && (sph_max || sph_max == 0) && (cyl_min || cyl_min == 0) && (cyl_max || cyl_max == 0))){
                    
                    if  (parseFloat(cyl_max) > parseFloat(cyl_min) && parseFloat(sph_max) > parseFloat(sph_min)) {
                        return true;
                   } else {
                    return false;
                   }
                }
           
           
            // if (
            //     (sph_min && sph_max && cyl_min && cyl_max) ||
            //     (sph_min === 0 && sph_max && cyl_min === 0 && cyl_max) ||
            //     (sph_min === 0 && sph_max && cyl_min && cyl_max) ||
            //     (cyl_min === 0 && cyl_max && sph_min && sph_max) || (sph_min === 0 && sph_max === 0 && cyl_min === 0 && cyl_max === 0) || (sph_min === 0 && sph_max === 0 && cyl_min && cyl_max === 0)) {
            //     return true;
            // } else {
            //     return false;
            // }
        }
    }

    fixVerticalTabindex(selector) {
        if (typeof selector == 'undefined') {
            selector = '.reset-tabindex';
        }
        let tabindex = 1;
        $(selector).each((i, tbl) => {
            $(tbl).find('tr').first().find('td').each((clmn, el) => {
                $(tbl).find('tr td:nth-child(' + (clmn + 1) + ') div input').each((j, input) => {
                    $(input).attr('placeholder', tabindex);
                    $(input).attr('tabindex', tabindex++);
                });
            });
        });
    }

      /**
     * @name saveStockRequest
     * @param {FormGroup} requestForm
     * @returns payload form cxtypedetail
     */
    saveStockRequest(requestForm: FormGroup) {
        this.loadingIndicator = true;
        const sph_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('min').value;
        const sph_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('max').value;
        const cyl_min = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('min').value;
        const cyl_max = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('max').value;
        const cyl_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(1).get('valueType').value;
        const sph_valueType = ((this.sectionsArray as FormArray).at(0).get('childern') as FormArray).at(0).get('valueType').value;
        let payload = {};
        payload = {
            sphmin: sph_min,
            sphmax: sph_max,
            cylmin: cyl_min,
            cylmax: cyl_max,
            rangeStockListPayload: this.rangeStockListPayload,
            totalQTY: this.totalQTY
        };
        // const payload = {
        //     sphmin: sph_valueType ? -sph_min : sph_min,
        //     sphmax: sph_valueType ? -sph_max : sph_max,
        //     cylmin: cyl_valueType ? -cyl_min : cyl_min,
        //     cylmax: cyl_valueType ? -cyl_max : cyl_max,
        //     rangeStockListPayload: this.rangeStockListPayload,
        //     totalQTY: this.totalQTY
        // };
        if (requestForm.invalid) {
            return false;
        }
         this.optSelected.emit(payload);
        setTimeout(() => {
            this.loadingIndicator = false;
        },300)
        
    }
    /**
     * @name destroy component
     */
    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        // this.subscription.unsubscribe();
    }
}
