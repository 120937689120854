<dx-load-panel
[hideOnOutsideClick]="false"
shadingColor="rgba(0,0,0,0.4)"
[(visible)]="loadingIndicator">
</dx-load-panel>
<div class="form-grid-container mt-3">
  <div class="">
    <form [formGroup]="requestForm">
      <ng-container class="row mt-3" formArrayName="sections">
        <ng-container *ngFor="let section of sectionsArray.controls; let i = index">
          <ng-container [formGroupName]="i">
              <!-- <dx-button id="add-row" text="Add Rows" type="normal" class="float-right" (click)="addRows()">
              </dx-button> -->
              <span class="total-count" *ngIf="totalToggle">Total: {{getTotal}}</span>
              <span>
                <mat-slide-toggle class="ml-3" color="primary" (change)="toggleStock('previous')"
                 *ngIf="showPreviousStock"> Available Stock           
                </mat-slide-toggle>
              </span>
              <span>
                <mat-slide-toggle class="ml-3" (change)="toggleStock('current')"
                 *ngIf="showCurrentStock"> Audit Stock       
                </mat-slide-toggle>
              </span>
            <div class="clearfix"></div>
            <div class="row mt-2" *ngIf="getCxTypeDetail(section).length > 0">
              <div class="col">
                <div class="card animated fadeIn">
                  <div class="card-body pb-0">
                    <div class="table-responsive-sm">
                      <ng-container formArrayName="cxTypeDetail">
                        <table
                          class="table table-striped use-equal-cell-width text-center table-with-over reset-tabindex">
                          <thead>
                            <tr>
                              <th>S#.</th>
                              <th *ngFor="let item of cxHeader">{{item.name}}</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr [formGroupName]="k" *ngFor="let item of getCxTypeDetail(section); let k = index">
                              <td>{{k + 1}}</td>
                              <td>
                                <dx-select-box [inputAttr]="{ autocomplete: 'off' }" [readOnly]="readonly"  [items]="cxRangeList" 
                            placeholder="Select Cx"
                                [showClearButton]="false" displayExpr="cxValue" valueExpr="cxValue"
                                [searchEnabled]="true" formControlName="cx" [tabIndex]="k + 1"
                                (onSelectionChanged)="getItemCatalogDetail(item.get('cx').value, k)">
                            </dx-select-box>
                                <!-- <select class="form-control-sm w-100" formControlName="cx" tabindex="{{k + 1}}" id="input-{{k}}" (change)="getItemCatalogDetail($event.target.value, k)">
                                  <option value="">Select Cx</option>
                                  <option *ngFor="let c of cxRangeList" [value]="c.cxId">
                                    {{c.cxValue}}
                                  </option>
                                </select> -->
                                <!-- <input tabindex="{{k + 1}}" [type]="inputType ? 'text' : 'number'" id="input-{{k}}"
                                  (input)="validateCxValue($event.target.value,k)" formControlName="cx"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"> -->
                                </td>
                                <td class="position-relative" [ngClass]="{'hightLight': item.get('vendorItemCode').value  > 0 || item.get('vendorItemCode').value < 0}">
                                  <span class="availableStock badge badge-primary" [ngStyle]="{'visibility': previousStock ? 'visible' : 'hidden'}">{{item.get('availableStock').value}}</span>
                                  <input tabindex="{{k + 1}}" SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" NumberValidate
                                  formControlName="vendorItemCode" SignNotAllowed="-" min="0"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off" readonly="readonly" >
                                  <span class="currentStock badge badge-info"  [ngStyle]="{'visibility': currentStock ? 'visible' : 'hidden'}">{{item.get('currentStock').value}}</span>
                                </td>
                              <td>
                                <dx-select-box [inputAttr]="{ autocomplete: 'off' }"  [items]="cxList" 
                            placeholder="Select Type" [readOnly]="readonly"
                                [showClearButton]="true" displayExpr="cxtypeName" valueExpr="cxtypeId"
                                [searchEnabled]="false" formControlName="cxtypeId" 
                                >
                            </dx-select-box>
                                <!-- <select class="form-control-sm w-100" formControlName="cxtypeId" [disabled]="true">
                                  <option value="">Select Cx Type</option>
                                  <option *ngFor="let cx of cxList" [value]="cx.cxtypeId">
                                    {{cx.cxtypeName}}
                                  </option>
                                </select> -->
                              </td>
                              
                             

                              <td><input SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" formControlName="cc"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"></td>
                              <td><input SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" formControlName="add"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"></td>
                              <!-- <td><input type="text" formControlName="axis" class="form-control form-control-sm hvr-glow animated fadeInRight" autocomplete="off"></td>
                            <td><input type="text" formControlName="cyl" class="form-control form-control-sm hvr-glow animated fadeInRight" autocomplete="off"></td> -->
                              <td><input SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" formControlName="ct"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"></td>
                              <td><input SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" formControlName="et"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"></td>
                              <td><input SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" formControlName="tc"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"></td>
                              <td><input SignNotAllowed="{{inputType ? '' : '101'}}" [type]="inputType ? 'text' : 'number'" formControlName="wt"
                                  [readonly]="readonly"
                                  class="form-control form-control-sm hvr-glow animated fadeInRight text-center"
                                  autocomplete="off"></td>
                              <td><a href="javascript:void(0)" class="cursor-pointer warn">
                                  <mat-icon (click)="removeIndex(k)">delete_forever</mat-icon>
                                </a></td>
                            </tr>
                          </tbody>
                        </table>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col  animated fadeInUp" *ngIf="validateSubmitBtn">
                <dx-button id="button" [text]="buttonText" type="default" class="float-right ml-3"
                  (click)="!requestForm.valid || saveStockRequest(requestForm)" [useSubmitBehavior]="true">
                </dx-button>
                <dx-button text="Cancel" type="normal" class="float-right" appBackButton>
                </dx-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>