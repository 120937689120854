import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AddFormComponent } from "./form-grid/add-form/add-form.component";
import { AddfAuditReconciliationComponent } from "./form-grid/addf-audit-form/addf-audit-reconciliation.component";
import { AppPanelComponent } from "./app-panel/app-panel.component";
import { BackButtonDirective } from "./directives/back-button.directive";
import { BlockKeyUpDown } from "./directives/block-keyup-down.directive";
import { BulkAddfComponent } from "./form-grid/bulk-add/bulk-add.component";
import { BulkCylSphComponent } from "./form-grid/bulk-cyl-sph/bulk-cyl-sph.component";
import { CaptureBoxComponent } from "./capture-box/capture-box.component";
import { CommonModule } from '@angular/common';
import { CopyTemplateMplComponent } from "./copy-template-mpl/copy-template-mpl.component";
import { CylFormComponent } from "./form-grid/cyl-form/cyl-form.component";
import { CylStockAuditComponent } from "./form-grid/cyl-stock-audit/cyl-stock-audit.component";
import { CylWithChecboxComponent } from "./form-grid/cyl-with-checbox/cyl-with-checbox.component";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { DialogAddRows } from "./form-grid/dilog/add-rows.component";
import { DisableControlDirective } from "./directives/disabled-control.directive";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DxActionSheetModule } from "devextreme-angular/ui/action-sheet";
import { DxBulletModule } from "devextreme-angular/ui/bullet";
import { DxButtonModule } from "devextreme-angular/ui/button";
import { DxCheckBoxModule } from "devextreme-angular/ui/check-box";
import { DxDataGridModule } from "devextreme-angular/ui/data-grid";
import { DxDateBoxModule } from "devextreme-angular/ui/date-box";
import { DxFormModule } from "devextreme-angular/ui/form";
import { DxLoadIndicatorModule } from "devextreme-angular/ui/load-indicator";
import { DxLoadPanelModule } from "devextreme-angular/ui/load-panel";
import { DxLookupModule } from "devextreme-angular/ui/lookup";
import { DxNumberBoxModule } from "devextreme-angular/ui/number-box";
import { DxPopoverModule } from "devextreme-angular/ui/popover";
import { DxPopupModule } from "devextreme-angular/ui/popup";
import { DxRadioGroupModule } from "devextreme-angular/ui/radio-group";
import { DxScrollViewModule } from "devextreme-angular/ui/scroll-view";
import { DxSelectBoxModule } from "devextreme-angular/ui/select-box";
import { DxSpeedDialActionModule } from "devextreme-angular/ui/speed-dial-action";
import { DxSwitchModule } from "devextreme-angular/ui/switch";
import { DxTagBoxModule } from "devextreme-angular/ui/tag-box";
import { DxTemplateModule } from "devextreme-angular/core";
import { DxTextAreaModule } from "devextreme-angular/ui/text-area";
import { DxTextBoxModule } from "devextreme-angular/ui/text-box";
import { DxTooltipModule } from "devextreme-angular/ui/tooltip";
import { DxValidationSummaryModule } from "devextreme-angular/ui/validation-summary";
import { DxValidatorModule } from "devextreme-angular/ui/validator";
import { FilterSingleItemComponent } from './filter-single-item/filter-single-item.component';
import { FiltersComponent } from "./filters/filters.component";
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormGridComponent } from "./form-grid/form-grid.component";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { InventoryCXGridComponent } from "./form-grid/inventory-cx/inventory-cx.component";
import { ItemDetailComponent } from "./item-detail/item-detail.component";
import { LabelModule } from "@progress/kendo-angular-label";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MaxLengthValidate } from "./directives/maxlength-validate";
import { McMasterComponent } from "./mc-master/mc-master.component";
import { MultiFiltersComponent } from "./multi-filters/multi-filters.component";
import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { NumberValidate } from "./directives/number-validate";
import { ObserversModule } from '@angular/cdk/observers';
import { OrderAmountComponent } from "./order-amount/order-amount.component";
import { OrderAmountFittingComponent } from "./order-amount-fitting/order-amount-fitting.component";
import { OrderAmountJobComponent } from "./order-amount-job/order-amount-job.component";
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { Papa } from "ngx-papaparse";
import { PriceComponent } from "./price/price.component";
import { PriceNewComponent } from './price-new/price-new.component';
import { RangeTemplateFNSpecialDialog } from "./range-template-fn-special/range-template-fn-special";
import { RangeTemplateRXSpecialDialog } from "./range-template-rx-special/range-template-rx-special";
import { ReconciliationCXGridComponent } from "./form-grid/reconciliation-cx/reconciliation-cx.component";
import { RingToneService } from "./../../services/ringtone.service";
import { SaleInquiryFilterComponent } from "./sale-inquiry-filter/sale-inquiry-filter.component";
import { SharedService } from "./shared.service";
import { SignNotAllowed } from "./directives/sign-not-allowed.directive";
import { StockFormComponent } from "./stock-form/stock-form.component";
import { StringFilterByPipe } from "./pipes/string-filter.pipe";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { ToFixedDirective } from "./validators/toFixed.directive";
import { UserAccessibilityDirective } from "./directives/user-accessibility.directive";
import { UserService } from "./core/user.service";
import { WebcamModule } from "ngx-webcam";
import { NgScrollbarModule } from "ngx-scrollbar";

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgxDatatableModule,
    ReactiveFormsModule,
     FormsModule,
    // Papa,
    DxNumberBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxButtonModule,
    DxPopoverModule,
    DxSwitchModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxSelectBoxModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxTextAreaModule,
    DxFormModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxTooltipModule,
    DxPopupModule,
    SweetAlert2Module,
    WebcamModule,
    MatBadgeModule,
    DxScrollViewModule,
    DxRadioGroupModule,
    ObserversModule,
    DxSpeedDialActionModule,
    NgSelectModule,
    DropDownsModule,
    InputsModule,
    LabelModule,
    DateInputsModule,
    NgScrollbarModule
  ],
  exports: [
    AppPanelComponent,
    PriceComponent,
    FlexLayoutModule,
    DxNumberBoxModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    NgxDatatableModule,
    MatTableModule,
    BackButtonDirective,
    FormGridComponent,
    DialogAddRows,
    CylFormComponent,
    AddFormComponent,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxButtonModule,
    DxPopoverModule,
    DxSwitchModule,
    DxActionSheetModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxSelectBoxModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxLookupModule,
    DxTextAreaModule,
    DxFormModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxTagBoxModule,
    DxDateBoxModule,
    DxTooltipModule,
    ToFixedDirective,
    SignNotAllowed,
    UserAccessibilityDirective,
    NumberValidate,
    FiltersComponent,
    SaleInquiryFilterComponent,
    SweetAlert2Module,
     DxPopupModule,
    McMasterComponent,
    MultiFiltersComponent,
    OrderTrackingComponent,
    CaptureBoxComponent,
    WebcamModule,
    CopyTemplateMplComponent,
    MatBadgeModule,
    InventoryCXGridComponent,
    CylWithChecboxComponent,
    CylStockAuditComponent,
    AddfAuditReconciliationComponent,
    ReconciliationCXGridComponent,
    DxScrollViewModule,
    DxRadioGroupModule,
    ObserversModule,

    ReactiveFormsModule,
    FormsModule,
    BlockKeyUpDown,
    StringFilterByPipe,
    PriceNewComponent,
    OrderAmountComponent,
    BulkCylSphComponent,
    DxSpeedDialActionModule,
    BulkAddfComponent,
    FilterSingleItemComponent,
    NgSelectModule,
    DisableControlDirective,
    OrderAmountJobComponent,
    OrderAmountFittingComponent,
    ItemDetailComponent,
    DropDownsModule,
    InputsModule,
    LabelModule,
    DateInputsModule,
    MaxLengthValidate,
    NgScrollbarModule
  ],
  declarations: [
    BackButtonDirective,
    FormGridComponent,
     DialogAddRows,
     CylFormComponent,
     AddFormComponent,
     AppPanelComponent,
    PriceComponent,
    ToFixedDirective,
    SignNotAllowed,
    UserAccessibilityDirective,
    NumberValidate,
    FiltersComponent,
    SaleInquiryFilterComponent,
    McMasterComponent,
    MultiFiltersComponent,
    OrderTrackingComponent,
    CaptureBoxComponent,
    CopyTemplateMplComponent,
    InventoryCXGridComponent,
    CylWithChecboxComponent,
    CylStockAuditComponent,
    AddfAuditReconciliationComponent,
    ReconciliationCXGridComponent,
    BlockKeyUpDown,
    StringFilterByPipe,
    PriceNewComponent,
    OrderAmountComponent,
    BulkCylSphComponent,
    BulkAddfComponent,
    RangeTemplateFNSpecialDialog,
    FilterSingleItemComponent,
    DisableControlDirective,
    OrderAmountJobComponent,
    OrderAmountFittingComponent,
    ItemDetailComponent,
    RangeTemplateRXSpecialDialog,
    MaxLengthValidate,
    StockFormComponent
  ],
  providers: [UserService, SharedService, RingToneService]
})
export class SharedModule {
  
 }
