import { Injectable } from "@angular/core";
import { Location } from "@angular/common";
import { Menu } from "./menu.model";

@Injectable()
export class MenuService {
  constructor(private location: Location) {}

  public getVerticalMenuItems(): Array<Menu> {
    let horizontalMenu = [];
    if (JSON.parse(localStorage.getItem("userData")).isAdmin) {
      horizontalMenu.push(new Menu(1, 'Setup Login', 'customers/customer-contact-login', null, null, null, false, 0));
    }
    horizontalMenu.push(new Menu(2, 'Inquiry', 'sales/sales-inquiry/index', null, null, null, false, 0));
    horizontalMenu.push(new Menu(3, 'Job Order', 'sales/job-order', null, null, null, false, 0));
    horizontalMenu.push(new Menu(6, 'Stock Order', 'sales/stock-order', null, null, null, false, 0));
    horizontalMenu.push(new Menu(7, 'Generate Report', 'reports/generate-report/index', null, null, null, false, 0));
    return horizontalMenu;
  }

  public getHorizontalMenuItems(): Array<Menu> {
    let horizontalMenu = [];
    if (JSON.parse(localStorage.getItem("userData")).isAdmin) {
      horizontalMenu.push(new Menu(1, 'Setup Login', 'customers/customer-contact-login', null, null, null, false, 0));
    }
    horizontalMenu.push(new Menu(2, 'Inquiry', 'sales/sales-inquiry/index', null, null, null, false, 0));
    horizontalMenu.push(new Menu(3, 'Job Order', 'sales/job-order', null, null, null, false, 0));
   
    horizontalMenu.push(new Menu(6, 'Stock Order', 'sales/stock-order', null, null, null, false, 0));
    horizontalMenu.push(new Menu(7, 'Generate Report', 'reports/generate-report/index', null, null, null, false, 0));
    // if (JSON.parse(localStorage.getItem("userData")).isAdmin) {
    // horizontalMenu.push(new Menu(7, 'SOA Request', 'soa-request', null, null, null, false, 0));
    // }
    return horizontalMenu;
  }

  static publicVerifyMenu(routerLink: string) {
    let userMenu: any = JSON.parse(localStorage.getItem("userData"));
    userMenu.forEach(item => {
      if (item.canView && item.formUrl == routerLink) {
        return true;
      } else {
        return false;
      }
    });
  }
  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter(item => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(
          item => item.id == menuItem.parentId
        )[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.id);
      }
    }
  }

  public toggleMenuItem(menuId) {
    let menuItem = document.getElementById("menu-item-" + menuId);
    let subMenu = document.getElementById("sub-menu-" + menuId);
    if (subMenu) {
      if (subMenu.classList.contains("show")) {
        subMenu.classList.remove("show");
        menuItem.classList.remove("expanded");
      } else {
        subMenu.classList.add("show");
        menuItem.classList.add("expanded");
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter(item => item.id == menuId)[0];
    if (currentMenuItem.parentId == 0 && !currentMenuItem.target) {
      menu.forEach(item => {
        if (item.id != menuId) {
          let subMenu = document.getElementById("sub-menu-" + item.id);
          let menuItem = document.getElementById("menu-item-" + item.id);
          if (subMenu) {
            if (subMenu.classList.contains("show")) {
              subMenu.classList.remove("show");
              menuItem.classList.remove("expanded");
            }
          }
        }
      });
    }
  }
}
