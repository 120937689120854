import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AppSettings } from '../../app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuService } from '../../template/theme/components/menu/menu.service';
import { Settings } from '../../app.settings.model';
import { StorageService } from '../../services/branchstorage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [MenuService]
})
export class PagesComponent implements OnInit {
  currentRoute: string;
  userRights: any = [];
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('backToTop') backToTop: any;
  @ViewChild('companyBranchSelectButton') companyBranchSelectButton;
  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public companyName: string;
  public isStickyMenu: boolean = false;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized
  public isPanelOpen: boolean = false;
  public userBranches = null;
  public customerBranchId = null;
  public companyBranchName = null;
  public CompanyArabicName = null;
  public customerName: string;
  public selectedCustomerBranch: string;
  languages: any;
  selectedLanguage: any;
  @ViewChild('mainSidenavContent') mainSidenavContent: any; 
  @ViewChild('mainContent') mainContent: ElementRef; 
  constructor(private _snackBar: MatSnackBar, public appSettings: AppSettings, public router: Router, private menuService: MenuService, 
    private bService: StorageService, private translate: TranslateService) {
    this.settings = this.appSettings.settings;
    this.userBranches = JSON.parse(localStorage.getItem('userData')).customerBranch;
    this.customerName = JSON.parse(localStorage.getItem('userData')).companyBranchName;

    this.customerBranchId = bService.getBranch.customerBranchId;
    this.selectedCustomerBranch = bService.getBranch.customerBranchName;
    // this.setCompanyBranch(bService.getBranch.customerBranchId);
    let currentBranch = this.userBranches.find(obj => obj.customerBranchId == bService.getBranch.customerBranchId);
    this.companyBranchName = currentBranch.customerBranchName;
    this.companyName = JSON.parse(localStorage.getItem('selectedCustomerBranch')).companyName;
    this.CompanyArabicName = JSON.parse(localStorage.getItem('userData')).companyArabicName;

    this.bService.setBranch = currentBranch;
    localStorage.setItem('selectedCustomerBranch', JSON.stringify(currentBranch));
  }

  ngOnInit() {
    this.languages = [
      {
        'id': 'en',
        'title': 'English',
        'flag': 'us'
      },
      {
        'id': 'ksa',
        'title': 'KSA',
        'flag': 'ksa'
      },

    ];
    this.selectedLanguage = this.languages[0];


    this.currentRoute = this.router.url;
    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
    this.defaultMenu = this.settings.menu;
  }
  setCompanyBranch(val) {
    let currentBranch = this.userBranches.find(obj => obj.customerBranchId == val);
    this.companyBranchName = currentBranch.companyBranchName;
    this.companyName = currentBranch.companyName;
    // this.CompanyArabicName = currentBranch.companyArabicName;
    this.CompanyArabicName = JSON.parse(localStorage.getItem('userData')).companyArabicName;
    // this.companyName = JSON.parse(localStorage.getItem('selectedCustomerBranch')).companyName;
    // this.CompanyArabicName = JSON.parse(localStorage.getItem('selectedCustomerBranch')).companyArabicName;
    this.customerName = JSON.parse(localStorage.getItem('userData')).customerName;
    this.bService.setBranch = currentBranch;
    this.selectedCustomerBranch = currentBranch.customerBranchName;
    //this.router.navigate(['/portal/sales/sales-job']);

    // this.router.navigate(['/portal/dashboard']);
    this.router.navigate(['/portal/sales/job-order']);
   
    // axios.get('Account/GetUserRightByBranchId?userId='+JSON.parse(localStorage.getItem('userData')).userId+'&branchId='+this.customerBranchId)
    // .then((response) => {
    //   if (response.data.completed) {
    //      this.userRights = response.data.payload;
    //      localStorage.setItem('userRights', JSON.stringify(this.userRights));
    //      this.router.navigate(['/portal/dashboard']);
    //   } else {
    //       this.settings.loadingSpinner = false;
    //       this._snackBar.open(response.data.message, '', {
    //           duration: 3000
    //       });
    //   }
    // });

    localStorage.setItem('selectedCustomerBranch', JSON.stringify(currentBranch));

    this._snackBar.open(`Switched to "${this.selectedCustomerBranch}" branch !`, '', {
      duration: 2000
    });
  }
  toggleCompanyBranchSelectButtonPanel(bool) {
    if (bool) {
      this.isPanelOpen = true;
      setTimeout(() => {
        this.companyBranchSelectButton.open()
      }, 100);
    } else {
      this.companyBranchSelectButton.close();
      setTimeout(() => {
        this.isPanelOpen = false;
      }, 100);
    }
  }
  ngAfterViewInit() {
    setTimeout(() => { this.settings.loadingSpinner = false }, 300);
    this.backToTop.nativeElement.style.display = 'none';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu == "vertical")
      this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
  }


  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translate.use(lang.id);
  }


  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(['/']);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    (event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
    if (this.settings.menu == 'horizontal') {
      if (this.settings.fixedHeader) {
        var currentScrollTop = (event.target.scrollTop > 56) ? event.target.scrollTop : 0;
        (currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
        this.lastScrollTop = currentScrollTop;
      }
      else {
        (event.target.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
      }
    }
  }

  public scrollToTop() {
    this.mainSidenavContent.scrollTo({
      top: 0
    });
    this.mainContent.nativeElement.scrollTo({
      duration: 100,
      top: 0
    }); 
  }


  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical'
    }
    else {
      (this.defaultMenu == 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical'
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

}