import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import notify from "devextreme/ui/notify";

@Injectable()
export class UserService {
  public static userData: any;

  /**
   * @name constructor
   * @desc Class constructor
   * @return void
   */
  constructor(
    private snackBar: MatSnackBar,
  ) {
    this.setUserData(JSON.parse(localStorage.getItem('userData')));

  }

 
  /**
   * @name getUserData
   * @property get
   * @return Users data
   */
  getUserData() {
    return UserService.userData;
  }


  /**
   * @name setUserData
   * @param {object} userData
   * @property set
   * @return void
   */
  setUserData(userData) {
    UserService.userData = userData;
  }

  /**
   * @name showSnackBar
   * @param {string} snackBarText
   * @desc Show material design snack bar
   */
  showSnackBar(snackBarText: string): void {
    this.snackBar.open(snackBarText, 'Close', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
    });
  }
/**
   * @name showError
   * @param {*} success
   * @param {number} [width]
   * @param {number} [displayTime]
   * @memberof UserService
   */
  showError(error: any, width?: number, displayTime?: number) {
    const option = {
      width: width ? width : 300,
      displayTime: displayTime ? displayTime : 3000,
      message: error,
      type: 'error'
    };
    notify(option);
  }
 
  /**
   * @name showSuccess
   * @param {*} success
   * @param {number} [width]
   * @param {number} [displayTime]
   * @memberof UserService
   */
  showSuccess(success: any, width?: number, displayTime?: number) {
    const option = {
      width: width ? width : 300,
      displayTime: displayTime ? displayTime : 3000,
      message: success,
      type: 'success'
    };
    notify(option);
  }
}
