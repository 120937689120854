import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { SharedService } from './../shared.service';
import { UserService } from '../core/user.service';
import { Utils } from '../../../constants/utils';
import axios from 'axios';

declare var $: any;
export const APP_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-stock-form',
  templateUrl: './stock-form.component.html',
  styleUrls: ['./stock-form.component.scss']
})
export class StockFormComponent implements OnInit, OnChanges {

  @Input() componentTitle: string;
  @Input() data: any;
  // tslint:disable-next-line: no-output-rename
  @Output('optSelected') optSelected = new EventEmitter<any>();

  stockRequestForm: FormGroup;
  selectedDefaultBranchId;
  loadingIndicator: boolean;

  SPH_RANGE = [];
  CYL_RANGE = [];

  isSphInvalid = false;
  isCylInvalid = false;

  rangeStockListPayload = [];
   allCompanyBranch: any;
   adjustmentList: any;
  companyBranchStore1: any;
  // companyBranchStore2: any;
  allItemTypes: any;
  allItemGroups: any;
  
  constructor(private sharedService: SharedService, private userService: UserService, private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.selectedDefaultBranchId = JSON.parse(localStorage.getItem('selectedCustomerBranch')).companyBranchId;
    this.stockRequestForm = new FormGroup({
      requestedDate: new FormControl(new Date(), []),
      demandRequestedByBranchName: new FormControl(
        this.selectedDefaultBranchId,
        [Validators.required]
      ),
      demandRequestedByStoreName: new FormControl(
        this.selectedDefaultBranchId,
        [Validators.required]
      ),
      // demandRequestedByRequestedUser: new FormControl(
      //   { value: Utils.User ? Utils.User.userRole : 'null', disabled: true },
      //   []
      // ),
      demandRequestedByRequestedUserId: new FormControl(
        Utils.User ? Utils.User.userId : 'null',
        []
      ),
      adjustmentReason: new FormControl(null, [Validators.required]),
      // demandRequestedToBranchName: new FormControl(null, [Validators.required]),
      // demandRequestedToStoreName: new FormControl(null, [Validators.required]),
      itemType: new FormControl(null, [Validators.required]),
      itemGroup: new FormControl(null, [Validators.required]),
      rangeSphMin: new FormControl(null, [Validators.min(-15)]),
      rangeSphMax: new FormControl(null, [Validators.max(15)]),
      rangeCylMin: new FormControl(null, [Validators.min(-6)]),
      rangeCylMax: new FormControl(null, [Validators.max(0)]),
    });

    this.getAllCompanyBranch();
    this.getFirstCompanyBranchStore(this.selectedDefaultBranchId);
    this.getAllItemTypes();
    this.getAllItemGroups();
    this.getAdjustmentReason();
    if(this.activatedRoute.snapshot.params.id) {
      this.checkRange();
    }
   // this.checkRange();
  }
  /**
   * @name ngOnChanges
   * @desc Fires when change in input properties are detected
   * @return void
   */
  ngOnChanges () {  }
  /**
   * @name getAllCompanyBranch
   * @service SharedService
   * @return Promise
   */
  getAllCompanyBranch() {
    this.loadingIndicator = true;
    this.sharedService
      .getAllCompanyBranch()
      .then(response => {
        if (response.data.completed) {
          this.allCompanyBranch = response.data.payload;
          console.log('this.allCompanyBranch ', this.allCompanyBranch);
          this.loadingIndicator = false;
        }
      })
      .catch(err => {
        this.loadingIndicator = false;
        console.log('err ', err);
      });
  }
  /**
   * @name getFirstCompanyBranchStore
   * @service SharedService
   * @return Promise
   */
  getFirstCompanyBranchStore(branchId) {
    this.loadingIndicator = true;
    this.sharedService
      .getFirstCompanyBranchStore()
      .then(response => {
        if (response.data.completed) {
          this.companyBranchStore1 = response.data.payload;
          this.companyBranchStore1 = this.companyBranchStore1.filter(obj => {
            return obj.companyBranchId === branchId;
          });
          console.log(
            'branchId',
            branchId,
            'companyBranchStore1',
            this.companyBranchStore1
          );
          this.loadingIndicator = false;
        }
      })
      .catch(err => {
        this.loadingIndicator = false;
        console.log('err ', err);
      });
  }

  /**
   * @name getAllItemTypes
   * @service SharedService
   * @return Promise
   */
  getAllItemTypes() {
    this.loadingIndicator = true;
    this.sharedService
      .getAllItemTypes()
      .then(response => {
        if (response.data.completed) {
          this.allItemTypes = response.data.payload;
          console.log('allItemTypes ', this.allItemTypes);
          this.loadingIndicator = false;
        }
      })
      .catch(err => {
        this.loadingIndicator = false;
        console.log('err ', err);
      });
  }

  /**
   * @name getAllItemGroups
   * @service SharedService
   * @return Promise
   */
  getAllItemGroups() {
    this.loadingIndicator = true;
    this.sharedService
      .getAllItemGroups()
      .then(response => {
        if (response.data.completed) {
          this.allItemGroups = response.data.payload;
          console.log('allItemGroups ', this.allItemGroups);
          this.loadingIndicator = false;
        }
      })
      .catch(err => {
        this.loadingIndicator = false;
        console.log('err ', err);
      });
  }

  saveStockRequest(stockRequestForm: FormGroup) {
    if(stockRequestForm.invalid) {
      return false;
    }
    //this.loadingIndicator = true;
    const payload = {
      companyId: 1,
      // requestedByBranchId: stockRequestForm.value.demandRequestedByBranchName,
      // requestedToBranchId: stockRequestForm.value.demandRequestedToBranchName,
      stockRequestDate: stockRequestForm.value.requestedDate.toISOString(),
      requestTypeId: 1,
      // requestByStoreId: stockRequestForm.value.demandRequestedByStoreName,
      // requestToStoreId: stockRequestForm.value.demandRequestedToStoreName,
      itemGroupId: stockRequestForm.value.itemGroup,
      sphmin: stockRequestForm.value.rangeSphMin,
      sphmax: stockRequestForm.value.rangeSphMax,
      cylmin: stockRequestForm.value.rangeCylMin,
      cylmax: stockRequestForm.value.rangeCylMax,
      requestReasonNote: 'string',
      stockRequestedById:
        stockRequestForm.value.demandRequestedByRequestedUserId,
      rangeStockList: this.rangeStockListPayload,
      addDateTime: new Date().toISOString(),
      active: true,
      stockApprovalById: 1,
      approvalStatusId: 1,
      adjustmentReasonId: stockRequestForm.value.adjustmentReason
    };
    console.info('payload', payload);
    this.optSelected.emit(payload);
    // axios
    //   .post(`StockRequest/AddStockRequest`, payload)
    //   .then(response => {
    //     if (response.data.completed) {
    //       console.log("res ", response.data);
    //       this.stockRequestForm.reset({
    //         requestedDate: new Date(),
    //         demandRequestedByBranchName: this.selectedDefaultBranchId,
    //         demandRequestedByStoreName: this.selectedDefaultBranchId,
    //         demandRequestedByRequestedUser: Utils.User.userRole,
    //         demandRequestedByRequestedUserId: Utils.User.userId,
    //         demandRequestedToBranchName: null,
    //         demandRequestedToStoreName: null,
    //         itemType: null,
    //         itemGroup: null,
    //         rangeSphMin: null,
    //         rangeSphMax: null,
    //         rangeCylMin: null,
    //         rangeCylMax: null
    //       });
    //       this.SPH_RANGE = [];
    //       this.CYL_RANGE = [];
    //       this.rangeStockListPayload = [];
    //     }
    //     this.loadingIndicator = false;
    //   })
    //   .catch(err => {
    //     this.loadingIndicator = false;
    //     console.log("err ", err);
    //   });
  }

  getRangeValues(val, cyl, sph) {
    
    if (!val) {
      // if value empty then remove it from collection
      for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        if (
          this.rangeStockListPayload[i].cyl === cyl &&
          this.rangeStockListPayload[i].sph === sph
        ) {
          this.rangeStockListPayload.splice(i, 1);
          break;
        }
      }
      console.info('rangeStockListPayload ', this.rangeStockListPayload);
      return;
    }
    // check whether the value already exist then modify it
    if (this.rangeStockListPayload.some(o => o.cyl === cyl && o.sph === sph)) {
      for (let i = 0; i < this.rangeStockListPayload.length; i++) {
        if (
          this.rangeStockListPayload[i].cyl === cyl &&
          this.rangeStockListPayload[i].sph === sph
        ) {
          this.rangeStockListPayload[i]['qty'] = eval(val);
          break;
        }
      }
    } else {
      // else not found so push it
      let rangePairObj = {
        cyl: cyl,
        sph: sph,
        qty: eval(val)
      };
      this.rangeStockListPayload.push(rangePairObj);
    }
     console.info('rangeStockListPayload ', this.rangeStockListPayload);
  }

  checkRange() {
    let sph_min = this.stockRequestForm.value.rangeSphMin;
    let sph_max = this.stockRequestForm.value.rangeSphMax;
    let cyl_min = this.stockRequestForm.value.rangeCylMin;
    let cyl_max = this.stockRequestForm.value.rangeCylMax;
    if ((sph_min && sph_max) || (sph_min && sph_max == 0)) {
        (sph_max < sph_min) ? this.isSphInvalid = true : this.isSphInvalid = false;
        this.SPH_RANGE = this.sharedService.generateRange(sph_min, sph_max, 0.25);
        console.info('SphRange ', this.SPH_RANGE);
    
    } else {
        this.SPH_RANGE = null;
    }
    if ((cyl_min && cyl_max) || (cyl_min && cyl_max == 0)) {
        (cyl_max < cyl_min) ? this.isCylInvalid = true : this.isCylInvalid = false;
        this.CYL_RANGE = this.sharedService.generateRange(cyl_min, cyl_max, 0.25);
        console.info('CylRange ', this.CYL_RANGE);
    } else {
        this.CYL_RANGE = null;
    }
    if(this.activatedRoute.snapshot.params.id) {
      this.setInventoryFormData();
    }
    
}
// getSecondCompanyBranchStore(branchId) {
//  this.sharedService.getSecondCompanyBranchStore()
//       .then((response) => {
//           if (response.data.completed) {
//               this.companyBranchStore2 = response.data.payload;
//               this.companyBranchStore2 = this.companyBranchStore2.filter(obj => {
//                   return obj.companyBranchId === branchId;
//               });
//               console.log('branchId ', branchId, ' companyBranchStore2 ', this.companyBranchStore2);
//           }
//       })
//       .catch((err) => {
//           console.log('err ', err);
//       });
// }


/**
 * @name getAdjustmentReason
 * @service SharedService
 * @return Promise
 */
getAdjustmentReason() {
  this.sharedService.getAdjustmentReason()
  .then((response: any) => {
    if (response.data.completed) {
      this.loadingIndicator = false;
      console.log('adjustmentList', response.data.payload);
      this.adjustmentList =  response.data.payload;
    } else {
      this.loadingIndicator = false;
      this.userService.showSnackBar(response.data.message);
      console.error(response);
    }
  }).catch(error => {
    this.loadingIndicator = false;
    console.log(error);
    this.userService.showSnackBar(error.message);
  });
}
  /**
   * @name setInventoryFormData
   * @requires params SPH_RANGE or CYL_RANGE
   * @returns value
   */
  setInventoryFormData() {
    this.SPH_RANGE.forEach((sph, sphIndex) => {
      this.CYL_RANGE.forEach((cyl, index) => {
        this.data.rangeStockList.forEach(data => {
          if (data.sph == sph.toString() && data.cyl == cyl.toString()) {
            this.getRangeValues(data.qty, cyl, sph);
            setTimeout(() => {
              $('table tr td #input-' + sphIndex + '-' + index).val(data.qty);
            }, 10);
          }
        });
      });
    });
  }
}
