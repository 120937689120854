import { Component, OnDestroy, OnInit } from '@angular/core';

import { AppSettings } from '../../../../app.settings';
import { Router } from '@angular/router';
import { Settings } from '../../../../app.settings.model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit, OnDestroy {
  public settings: Settings;
  timeout: any;
  constructor(public appSettings:AppSettings, public router:Router) {
    this.settings = this.appSettings.settings; 
  }

  searchResult(): void {
    this.router.navigate(['/search']);
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.settings.loadingSpinner = false; 
    this.timeout = setTimeout(() => {
      this.router.navigate(['/portal/sales/job-order']);
      // this.router.navigate(['/portal/dashboard']);
    }, 1000);
  }
ngOnDestroy(): void {
  clearTimeout(this.timeout);
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
  
}
}